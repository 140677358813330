import { jsx as _jsx } from "react/jsx-runtime";
import { DefaultLayout } from '@pages/layout/DefaultLayout';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { ROUTES } from '@app/constants';
import { ProjectLayout } from '@pages/layout/ProjectLayout';
import { UsersPage } from '@pages/users';
import { ProjectSummaryPage } from '@pages/projectSummary';
import { DocumentUpload } from '@pages/Upload';
import { DocumentPreview } from '@pages/Preview';
import { DocumentReviewPage } from '@pages/documentReview';
import { DocumentResultsPage } from '@pages/Result';
import { ProjectsPage } from '@pages/projects';
import { DocumentsPage } from '@pages/documents';
import { DocumentLayout } from '@pages/layout/DocumentLayout';
const routes = [
    {
        path: '*',
        element: _jsx(Navigate, { to: ROUTES.PROJECTS })
    },
    {
        element: _jsx(DefaultLayout, {}),
        children: [
            {
                path: ROUTES.PROJECTS,
                element: _jsx(ProjectsPage, {})
            },
            {
                path: ROUTES.UPLOAD,
                element: _jsx(DocumentUpload, {})
            }
        ]
    },
    {
        element: _jsx(ProjectLayout, {}),
        children: [
            {
                path: ROUTES.PROJECT_SUMMARY,
                element: _jsx(ProjectSummaryPage, {})
            },
            {
                path: ROUTES.PROJECT_DOCUMENTS,
                element: _jsx(DocumentsPage, {})
            },
            {
                path: ROUTES.PROJECT_USERS,
                element: _jsx(UsersPage, {})
            }
        ]
    },
    {
        element: _jsx(DocumentLayout, {}),
        children: [
            {
                path: ROUTES.UPLOAD,
                element: _jsx(DocumentUpload, {})
            },
            {
                path: ROUTES.PREVIEW,
                element: _jsx(DocumentPreview, {})
            },
            {
                path: ROUTES.DOCUMENT_REVIEW,
                element: _jsx(DocumentReviewPage, {})
            },
            {
                path: ROUTES.DOCUMENT_SUMMARY,
                element: _jsx(DocumentResultsPage, {})
            }
        ]
    }
];
const router = createBrowserRouter(routes);
export default router;
