import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { useDocumentSummary } from '../lib/hooks/useDocumentSummary';
import { DocumentResultsPreparing } from './DocumentResultsPreparing';
import { Header } from './Header';
import { NotRelevantTable } from './NotRelevantTable';
import { ResultsTable } from './ResultsTable';
import styles from './SummaryTable.module.scss';
export function DocumentResultsPage() {
    const { documentId, document, data } = useDocumentSummary();
    if (!documentId || !document || !data) {
        return _jsx(DocumentResultsPreparing, {});
    }
    return (_jsxs(Box, { className: styles.container, children: [_jsx(Header, {}), _jsx(ResultsTable, { document: document, data: data, documentId: documentId }), _jsx(NotRelevantTable, { document: document, data: data, documentId: documentId })] }));
}
