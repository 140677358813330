import { useTranslationProject } from '@shared/lib/hooks';
import { DocumentService } from '../services/Document.service';
import { makeListQueryKey } from './utils/documents';
import { useMutation } from '@shared/lib/hooks';
import { useCurrentProject } from '@entities/project';
export function useRemoveDocument() {
    const { project } = useCurrentProject();
    const { tproject } = useTranslationProject();
    const listQueryOptions = { queryKey: makeListQueryKey(project.id) };
    const { mutate: removeDocument, isLoading: isRemoving, isSuccess: isRemoved } = useMutation({
        mutationFn: (documentId) => DocumentService.removeDocument(project.id, documentId).catch(() => {
            throw new Error(tproject('Error occurred. Please try to delete again.'));
        }),
        options: listQueryOptions
    });
    return {
        removeDocument,
        isRemoving,
        isRemoved
    };
}
