var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState, useRef, useCallback } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import Dropzone from 'react-dropzone';
import { MdCloudUpload, MdOutlineCloudDownload } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDocuments, useCurrentProject } from '@entities/project';
import { ROUTES } from '@app/constants';
import styles from './Upload.module.scss';
import { DocumentService } from '@entities/project/document/lib/services/Document.service';
import { useTranslationProject } from '@shared/lib/hooks';
import { ConfirmModal } from '@shared/ui';
export const DocumentUpload = ({ onClose }) => {
    const navigate = useNavigate();
    const { tproject } = useTranslationProject();
    const { t } = useTranslation();
    const { project } = useCurrentProject();
    const fileRef = useRef(null);
    const [file, setFile] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dragging, setDragging] = useState(false);
    const { refetch, documents } = useDocuments();
    useEffect(() => {
        localStorage.removeItem('pages');
        localStorage.removeItem('extraction');
    }, []);
    const handleCloseConfirmModal = () => {
        setShowConfirmModal(false);
    };
    const onDrop = useCallback((fileList) => {
        if (documents.length === 0) {
            setShowConfirmModal(true);
            fileRef.current = fileList[0];
        }
        else {
            setFile(fileList[0]);
        }
    }, [documents]);
    const handleConfirm = () => {
        setFile(fileRef.current);
    };
    useEffect(() => {
        handleContinue();
    }, [file]);
    const handleContinue = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            if (file) {
                const result = yield DocumentService.createDocument(file.name, project.id);
                if (result.status === 'success') {
                    const response = yield DocumentService.uploadDocument(result.url, file);
                    if (response) {
                        onClose && onClose();
                        navigate(ROUTES.PROJECT_DOCUMENTS.replace(':projectId', project.id));
                        refetch();
                    }
                }
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setLoading(false);
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(Box, { className: styles.container, children: _jsx(Box, { className: styles.content, children: !file ? (_jsx(Dropzone, { onDragLeave: () => setDragging(false), onDragEnter: () => setDragging(true), onDrop: onDrop, multiple: false, accept: {
                            'image/png': ['.png'],
                            'image/jpg': ['.jpg'],
                            'image/jpeg': ['.jpeg'],
                            'application/pdf': ['.pdf']
                        }, disabled: loading, children: ({ getRootProps, getInputProps }) => (_jsxs(Box, Object.assign({}, getRootProps(), { className: classNames(styles.dropzone, { [styles.active]: dragging }), children: [_jsx("input", Object.assign({}, getInputProps())), _jsx(MdCloudUpload, { size: 40, color: "#585858" }), _jsxs(Box, { className: styles.paragraph1, children: [_jsx("strong", { children: tproject('Drag and drop') }), ' ', tproject('file here or use the button below to upload it')] }), _jsx(Box, { className: styles.paragraph2, children: tproject('PDF, JPG, or PNG') }), _jsx(Button, { disabled: loading, startIcon: _jsx(MdOutlineCloudDownload, {}), children: tproject('Upload') })] }))) })) : (_jsxs(Box, { className: styles.file, children: [_jsx(CircularProgress, { size: 48 }), _jsx(Box, { children: tproject('Loading. Please wait...') }), _jsx(Box, { style: { fontWeight: 'normal' }, children: tproject('It can take 1—2 minutes, depending on number of pages in the file') })] })) }) }), _jsx(ConfirmModal, { open: showConfirmModal, title: tproject('Confirm Upload'), description: tproject('By uploading the first document for this project, I acknowledge that billing for the project will commence'), okButtonLabel: t('Confirm'), onClose: handleCloseConfirmModal, onProceed: () => {
                    handleConfirm();
                    handleCloseConfirmModal();
                } })] }));
};
