import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUsers } from '@entities/user/lib/hooks';
import { useTranslationUser } from '@shared/lib/hooks';
import classNames from 'classnames';
import { UserListSkeleton } from '@widgets/usersList/ui/UserListSkeleton';
import { Table } from '@shared/ui/Table';
import styles from './UserList.module.scss';
import { RemoveUser } from '@features/removeUser';
import { useCurrentProject } from '@entities/project';
import { useAuth } from '@entities/auth';
export function UsersList() {
    const { tuser } = useTranslationUser();
    const { project } = useCurrentProject();
    const { user } = useAuth();
    const { users, isLoading } = useUsers();
    const columns = makeColumns(tuser, user === null || user === void 0 ? void 0 : user.hasAdminRole, project.createdBy);
    return (_jsxs("div", { className: styles.container, children: [isLoading && _jsx(UserListSkeleton, {}), !isLoading && users.length === 0 && _jsx("div", { children: tuser('No users were found.') }), users.length > 0 && (_jsx(Table, { rows: users, loading: isLoading, columns: columns, disableRowSelectionOnClick: true, disableColumnResize: true, hideFooter: true, hideFooterSelectedRowCount: true, hideFooterPagination: true }))] }));
}
function makeColumns(tuser, isAdmin, ownerId) {
    const renderHeader = (...titles) => (_jsx("div", { className: styles.header, children: titles.map((title, index) => (_jsx("span", { children: tuser(title) }, index))) }));
    const columns = [
        {
            renderHeader: () => renderHeader(tuser('User Email')),
            field: 'email',
            flex: 8
        },
        {
            renderHeader: () => renderHeader(tuser('Status')),
            field: 'confirmed',
            headerClassName: styles.statusHeader,
            flex: 3,
            renderCell: ({ row }) => _jsx(ConfirmationStatus, { isConfirmed: row.confirmed })
        },
        {
            renderHeader: () => renderHeader(tuser('Delete')),
            field: 'delete',
            renderCell: ({ row }) => (ownerId !== row.id ? _jsx(RemoveUser, { user: row }) : null),
            flex: 1,
            align: 'center',
            sortable: false
        }
    ];
    if (!isAdmin) {
        columns.pop();
    }
    return columns;
}
function ConfirmationStatus({ isConfirmed }) {
    const { tuser } = useTranslationUser();
    return (_jsx("div", { className: classNames(styles.confirmationStatus, {
            [styles.confirmationStatus__pending]: !isConfirmed,
            [styles.confirmationStatus__confirmed]: isConfirmed
        }), children: isConfirmed ? tuser('Confirmed') : tuser('Pending') }));
}
