import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSummaryStore } from '@entities/summary';
import { useCurrentProject } from '@entities/project';
import { useTranslation } from 'react-i18next';
export function useDocumentSummary() {
    const { id } = useParams();
    const { t } = useTranslation();
    const { document, data, documentId, fetchSummary, reset } = useSummaryStore();
    const { project } = useCurrentProject();
    useEffect(() => {
        if (id) {
            fetchSummary(project.id, id);
        }
        return () => {
            reset();
        };
    }, [fetchSummary, id, t]);
    return {
        documentId,
        document,
        data
    };
}
