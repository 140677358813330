var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { PageTypes } from '@shared/types';
import { DocumentService } from '../services/Document.service';
const getInitialState = () => ({
    documentId: undefined,
    document: undefined,
    pageId: undefined,
    page: undefined,
    activePageIndex: 0,
    pageCount: 0
});
export const useDocumentStore = create()(devtools((set, get) => (Object.assign(Object.assign({}, getInitialState()), { fetchDocumentWithPage: (projectId, documentId, pageId) => __awaiter(void 0, void 0, void 0, function* () {
        Promise.all([
            DocumentService.getDocument(projectId, documentId),
            DocumentService.getPage(projectId, documentId, pageId)
        ]).then(([document, page]) => {
            const activePageIndex = Number(pageId || 0);
            set({
                documentId,
                document,
                pageId,
                page: modifyPageResponse(page),
                activePageIndex,
                pageCount: document.pageCount
            });
        });
    }), updatePage: (page) => {
        set({
            page
        });
    }, reset: () => {
        set(getInitialState());
    } }))));
export const isDocumentFetched = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDocumentStore(state => state.documentId && state.document && state.pageId && state.page);
};
export const isDocumentFetching = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDocumentStore(state => !state.documentId || !state.document || !state.pageId || !state.page);
};
export const calcTableRowCount = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDocumentStore(({ page }) => {
        var _a;
        return (_a = Object.values((page === null || page === void 0 ? void 0 : page.predictions.table) || {})) === null || _a === void 0 ? void 0 : _a.map(({ values }) => values.length).reduce((s, c) => (s > c ? s : c), 0);
    });
};
export const isPageValid = (tableRowCount) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDocumentStore(({ page }) => {
        if (!page) {
            return false;
        }
        if (page.predictions.type === PageTypes.undefined) {
            return true;
        }
        return !Object.keys(page.predictions).find((key) => {
            const value = page.predictions[key];
            if (key === 'table') {
                return !!Object.keys(value).find(property => {
                    if (property === 'certificate_number') {
                        return false;
                    }
                    // @ts-ignore
                    const values = value[property].values;
                    return values.length < tableRowCount || values.filter((v) => v === null).length;
                });
            }
            else {
                if (page.predictions.type === PageTypes.certificate) {
                    if (isPageField(value)) {
                        return value.prediction === null;
                    }
                    if (typeof value === 'string') {
                        return !(value in PageTypes);
                    }
                }
            }
        });
    });
};
function isPageField(field) {
    return Object.prototype.hasOwnProperty.call(field, 'prediction');
}
///
/// Refactor later
///
function modifyPageResponse(page) {
    return Object.assign(Object.assign({}, page), { predictions: Object.assign(Object.assign({}, page.predictions), { table: getTable(page) }) });
}
function getTable(page) {
    var _a;
    if (page.predictions.type === PageTypes.undefined) {
        return page.predictions.table;
    }
    const table = {};
    if (page.predictions) {
        const tableLength = (_a = Object.values((page === null || page === void 0 ? void 0 : page.predictions.table) || {})) === null || _a === void 0 ? void 0 : _a.map(({ values }) => values.length).reduce((s, c) => (s > c ? s : c), 0);
        Object.entries(page.predictions.table).forEach(([key, value]) => {
            const values = value.values.length > 0 ? value.values : Array.from({ length: tableLength }, () => null);
            table[key] = {
                coordinates: value.coordinates,
                values: values.map((v) => {
                    if (v) {
                        return v;
                    }
                    else {
                        return null;
                    }
                })
            };
        });
        // backward compatibility for table without material_name
        if (!table.material_name) {
            table.material_name = {
                coordinates: Array.from({ length: tableLength }, () => []),
                values: Array.from({ length: tableLength }, () => null)
            };
        }
    }
    return table;
}
