import { ToastService } from '@shared/lib/services';
import { DocumentFormats } from '../types/DocumentFormats';
import { downloadDocumentToCsv } from '../utils/downloadDocumentToCsv';
import { downloadDocumentToJson } from '../utils/downloadDocumentToJson';
import { filterResultDataByDocumentType } from '../utils/filterResultDataByDocumentType';
import { useTranslation } from 'react-i18next';
export function useSummaryDownload() {
    const { t } = useTranslation();
    function download(data, documentFormat, documentTypes, fileName) {
        if (fileName && data) {
            const preparedData = filterResultDataByDocumentType(data, documentTypes);
            if (documentFormat === DocumentFormats.json) {
                downloadDocumentToJson(preparedData, fileName);
            }
            else {
                downloadDocumentToCsv(preparedData, fileName);
            }
        }
        else {
            ToastService.error(t('Result data was not found'));
        }
    }
    return { download };
}
