import { memoize } from 'proxy-memoize';
import { DocumentResultErrorTypes } from '@shared/types/DocumentResultError';
const getResultErrorsByTypeMemo = memoize((errors) => {
    const groupedErrors = {
        [DocumentResultErrorTypes.error]: [],
        [DocumentResultErrorTypes.warning]: []
    };
    errors.forEach(error => {
        groupedErrors[error.type].push(error);
    });
    return groupedErrors;
});
export const getResultErrorsByType = ({ errors }) => {
    return getResultErrorsByTypeMemo(errors);
};
