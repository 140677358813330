import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { FixedSizeList as List } from 'react-window';
import ErrorTile from './ErrorTile';
import { useSummaryStore } from '@entities/summary';
import styles from './errorList.module.scss';
const ErrorList = ({ visible }) => {
    const { errors } = useSummaryStore();
    const errorItemSize = 56;
    const maxErrorContainerheight = 300;
    const errorContainerHeight = Math.min(errors.length * errorItemSize, maxErrorContainerheight);
    const Row = ({ index, style }) => (_jsx("div", { style: style, children: _jsx(ErrorTile, { errorDetails: errors[index], hasDivider: index !== errors.length - 1 }) }));
    return (_jsxs(Accordion, { expanded: visible, style: { boxShadow: 'none', border: 'none', background: 'transparent' }, children: [_jsx(AccordionSummary, { style: { display: 'none' } }), _jsx(AccordionDetails, { className: styles.accordianContainer, children: errors.length > 0 && (_jsx(List, { height: errorContainerHeight, itemCount: errors.length, itemSize: errorItemSize, width: "100%", children: Row })) })] }));
};
export default ErrorList;
