import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { DialogContainer, DialogActions, DialogContent, DialogTitle, DialogCloseButton } from '@shared/ui/Dialog';
import { useTranslation } from 'react-i18next';
import { useTranslationUser } from '@shared/lib/hooks';
import { Button } from '@shared/ui/Button';
import { CircularLoader } from '@shared/ui/Loader';
import { ToastService } from '@shared/lib/services';
import styles from './InviteUserDialog.module.scss';
import { UserInvitationForm } from '../UserInvitationForm';
import { useInviteUser } from '@entities/user/lib/hooks';
export function InviteUserDialog({ onClose }) {
    const { t } = useTranslation();
    const { tuser } = useTranslationUser();
    const { inviteUser, isInvited, isInviting } = useInviteUser();
    const handleSubmit = (formValues) => {
        inviteUser(formValues.email);
    };
    useEffect(() => {
        if (isInvited) {
            ToastService.success(tuser('Invitation was successfully sent.'));
            onClose();
        }
    }, [isInvited, onClose, tuser]);
    return (_jsxs(DialogContainer, { fullWidth: true, open: true, children: [_jsxs(DialogTitle, { className: styles.title, variant: "h5", children: [tuser('Invite user'), _jsx(DialogCloseButton, { onClick: onClose })] }), _jsx(DialogContent, { className: styles.content, children: _jsx(UserInvitationForm, { onSubmit: handleSubmit }) }), _jsxs(DialogActions, { className: styles.actions, children: [_jsx(Button, { variant: `outlined`, onClick: onClose, children: t('Cancel') }), _jsx(Button, { type: "submit", form: UserInvitationForm.FORM_ID, variant: "contained", disabled: isInviting, endIcon: isInviting ? _jsx(CircularLoader, { size: "1em", color: "inherit" }) : undefined, children: tuser('Send Invitation') })] })] }));
}
