import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { PageTypes } from '@shared/types';
import stylesDocument from '../document.module.scss';
import { DocumentTypeField, GeneralDataFields, TableDataFields } from '@features/document';
import { PageField } from './PageField';
import { ReviewSideNav } from './ReviewSideNav';
import { useDocumentStore, useSelectedFieldStore } from '@entities/project/document';
import { checkIfPageHasTableOnly } from '@entities/document';
import { TrimTextToolTip } from '@shared/ui';
import { useTranslationProject } from '@shared/lib/hooks';
export function DocumentReview() {
    const { field, index: fieldIndex } = useSelectedFieldStore();
    const { document, page } = useDocumentStore();
    const selectedRef = useRef(null);
    const { tproject } = useTranslationProject();
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        // @ts-ignore
        (_b = (_a = selectedRef.current) === null || _a === void 0 ? void 0 : _a.lastChild) === null || _b === void 0 ? void 0 : _b.focus();
        // @ts-ignore
        if (typeof ((_d = (_c = selectedRef.current) === null || _c === void 0 ? void 0 : _c.lastChild) === null || _d === void 0 ? void 0 : _d.select) !== 'undefined') {
            // @ts-ignore
            (_f = (_e = selectedRef.current) === null || _e === void 0 ? void 0 : _e.lastChild) === null || _f === void 0 ? void 0 : _f.select();
        }
        else {
            // @ts-ignore
            (_h = (_g = selectedRef.current) === null || _g === void 0 ? void 0 : _g.lastChild) === null || _h === void 0 ? void 0 : _h.focus();
        }
        // eslint-disable-next-line
    }, [field, fieldIndex]);
    return (_jsxs(Box, { className: stylesDocument.reviewSide, children: [_jsxs("div", { className: stylesDocument.header, children: [_jsxs(Typography, { className: stylesDocument.title, children: [tproject('File'), ":"] }), (document === null || document === void 0 ? void 0 : document.fileName) ? (_jsx(Typography, { className: stylesDocument.subTitle, children: _jsx(TrimTextToolTip, { title: decodeURI((document === null || document === void 0 ? void 0 : document.fileName) || ''), maxMessageLength: 30 }) })) : null, _jsx(PageField, {})] }), _jsxs(Box, { className: stylesDocument.formContent, children: [_jsx(DocumentTypeField, {}), (page === null || page === void 0 ? void 0 : page.predictions.type) === PageTypes.certificate && (_jsxs(_Fragment, { children: [_jsx(GeneralDataFields, { selectedRef: selectedRef }), _jsx(TableDataFields, { selectedRef: selectedRef })] })), checkIfPageHasTableOnly(page === null || page === void 0 ? void 0 : page.predictions.type) && (_jsx(TableDataFields, { selectedRef: selectedRef }))] }), _jsx("div", { className: stylesDocument.footer, children: _jsx(ReviewSideNav, {}) })] }));
}
