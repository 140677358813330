var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { vdaAPI } from '@shared/lib/api';
import { plainToInstance } from 'class-transformer';
import { DocumentModel } from '@entities/project/document';
import { SummaryModel } from '@entities/summary/models/Summary.model';
export class SummaryService {
    static getDocument(projectId, documentId) {
        return __awaiter(this, void 0, void 0, function* () {
            const documentResult = (yield vdaAPI
                .get(`projects/${projectId}/documents/${documentId}`)
                .json());
            return plainToInstance(DocumentModel, documentResult);
        });
    }
    static getDocumentEnricher(projectId, documentId) {
        return __awaiter(this, void 0, void 0, function* () {
            const documentEnricher = (yield vdaAPI
                .get(`projects/${projectId}/documents/${documentId}/enricher`)
                .json());
            return plainToInstance(SummaryModel, documentEnricher);
        });
    }
    static getProjectEnricher(projectId) {
        return __awaiter(this, void 0, void 0, function* () {
            const projectEnricher = (yield vdaAPI
                .get(`projects/${projectId}/enricher`)
                .json());
            return plainToInstance(SummaryModel, projectEnricher);
        });
    }
}
