import { ResultFieldProperties } from '@shared/types/ResultFieldProperties';
export const resultFields = [
    {
        label: 'Page Number',
        property: ResultFieldProperties.pageNumber
    },
    {
        label: 'File name',
        property: ResultFieldProperties.filename
    },
    {
        label: 'Document type',
        property: ResultFieldProperties.type
    },
    {
        label: 'Company name',
        property: ResultFieldProperties.companyName
    },
    {
        label: 'Shipping date',
        property: ResultFieldProperties.shippingDate
    },
    {
        label: 'Certificate number',
        property: ResultFieldProperties.certificateNumber
    },
    {
        label: 'Document number',
        property: ResultFieldProperties.documentNumber
    },
    {
        label: 'Material & Standard Name',
        property: ResultFieldProperties.materialName
    },
    {
        label: 'Total amount',
        property: ResultFieldProperties.totalAmount
    },
    {
        label: 'Diameter',
        property: ResultFieldProperties.diameter
    },
    {
        label: 'Length',
        property: ResultFieldProperties.length
    },
    {
        label: 'ID',
        property: ResultFieldProperties.id
    },
    {
        label: 'Quantities',
        property: ResultFieldProperties.quantities
    }
];
