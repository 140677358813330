import { useEffect, useState } from 'react';
export function useLazyImageLoad(imageUrl) {
    const [isFetching, setIsFetching] = useState(true);
    const [isFetched, setIsFetched] = useState(false);
    useEffect(() => {
        setIsFetching(true);
        setIsFetched(false);
        if (imageUrl) {
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => {
                setIsFetched(true);
                setIsFetching(false);
            };
        }
    }, [imageUrl]);
    return {
        isFetching,
        isFetched
    };
}
