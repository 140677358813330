import { ToastService } from '@shared/lib/services';
import { DocumentFormats } from '../types/DocumentFormats';
import { exportDocumentToCsv } from '../utils/exportDocumentToCsv';
import { exportDocumentToJson } from '../utils/exportDocumentToJson';
import { filterResultDataByDocumentType } from '../utils/filterResultDataByDocumentType';
import { useTranslation } from 'react-i18next';
export function useSummaryCopy() {
    const { t } = useTranslation();
    function copy(data, documentFormat, documentTypes, fileName) {
        let res = '';
        if (fileName && data) {
            const preparedData = filterResultDataByDocumentType(data, documentTypes);
            if (documentFormat === DocumentFormats.json) {
                res = exportDocumentToJson(preparedData);
            }
            else {
                res = exportDocumentToCsv(preparedData, fileName, false);
            }
        }
        else {
            ToastService.error(t('Result data was not found'));
            return '';
        }
        return res === undefined ? '' : res;
    }
    return { copy };
}
