import { useTranslationUser } from '@shared/lib/hooks';
import { UserService } from '../services/User.service';
import { makeListQueryKey } from './utils/user';
import { useMutation } from '@shared/lib/hooks';
import { useCurrentProject } from '@entities/project';
export function useInviteUser() {
    const { project } = useCurrentProject();
    const { tuser } = useTranslationUser();
    const listQueryOptions = { queryKey: makeListQueryKey(project.id) };
    const { mutate: inviteUser, isLoading: isInviting, isSuccess: isInvited } = useMutation({
        mutationFn: email => UserService.inviteUser(project.id, email).catch(() => {
            throw new Error(tuser('Error occurred. Please try to send new invitation.'));
        }),
        options: listQueryOptions
    });
    return {
        inviteUser,
        isInviting,
        isInvited
    };
}
