import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton } from '@shared/ui/Button';
import { DeleteIcon } from '@shared/ui/icons';
import styles from '@widgets/usersList/ui/UserList.module.scss';
import { DeleteUserDialog } from '@entities/user/ui';
import { useState } from 'react';
export function RemoveUser({ user }) {
    const [userBeingRemoved, setUserBeingRemoved] = useState();
    const handleUserRemove = () => {
        setUserBeingRemoved(user);
    };
    const handleDeleteUserDialogClose = () => setUserBeingRemoved(undefined);
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { onClick: handleUserRemove, children: _jsx(DeleteIcon, { className: styles.deleteIcon }) }), userBeingRemoved && (_jsx(DeleteUserDialog, { user: userBeingRemoved, onSuccess: handleDeleteUserDialogClose, onClose: handleDeleteUserDialogClose }))] }));
}
