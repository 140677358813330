import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { TextField } from '@shared/ui/TextField';
import { useTranslationProject } from '@shared/lib/hooks';
import styles from './ProjectForm.module.scss';
import { Formik } from 'formik';
export function ProjectForm({ onSubmit, name, location }) {
    const { tproject } = useTranslationProject();
    const validate = useMemo(() => getValidate(tproject), [tproject]);
    return (_jsx(Formik, { initialValues: {
            name,
            location
        }, onSubmit: onSubmit, validate: validate, children: ({ handleChange, handleBlur, handleSubmit, values, errors }) => (_jsxs("form", { id: FORM_ID, onSubmit: handleSubmit, noValidate: true, children: [_jsx(TextField, { className: styles.emailField, onChange: handleChange, onBlur: handleBlur, name: "name", type: "text", label: tproject('Project Name'), value: values.name, error: !!errors.name, helperText: errors.name || ' ', required: true, fullWidth: true }), _jsx(TextField, { className: styles.emailField, onChange: handleChange, onBlur: handleBlur, name: "location", type: "text", label: tproject('Location'), value: values.location, error: !!errors.location, helperText: errors.location || ' ', required: true, fullWidth: true })] })) }));
}
const FORM_ID = 'new-project';
ProjectForm.FORM_ID = FORM_ID;
const getFieldValidations = (tproject) => {
    return {
        name: (name) => {
            if (!name.trim()) {
                return tproject('Please enter a valid name');
            }
            return undefined;
        },
        location: (location) => {
            if (!location.trim()) {
                return tproject('Please enter a valid location');
            }
            return undefined;
        }
    };
};
function getValidate(tproject) {
    const validateFnMap = getFieldValidations(tproject);
    return function (values) {
        const errors = {};
        for (const key of Object.keys(validateFnMap)) {
            const value = values[key] || '';
            const error = validateFnMap[key](value);
            if (error) {
                errors[key] = error;
            }
        }
        return errors;
    };
}
