var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import ky from 'ky';
import { AuthService } from '@shared/lib/services';
export const api = ky.create({
    timeout: 30000,
    headers: {
        accept: 'application/json',
        'content-type': 'application/json'
    },
    hooks: {
        afterResponse: [
            (request, options, response) => __awaiter(void 0, void 0, void 0, function* () {
                if (response.status === 401) {
                    yield AuthService.logout();
                }
            }),
            (request, options, response) => __awaiter(void 0, void 0, void 0, function* () {
                if (response.status === 403) {
                    const message = 'You do not have permission to view the requested page';
                    throw new Error(message);
                }
            }),
            (request, options, response) => __awaiter(void 0, void 0, void 0, function* () {
                if (response.status === 500) {
                    throw new Error(response.statusText);
                }
            })
        ]
    }
});
export const apiWithAuth = api.extend({
    hooks: {
        beforeRequest: [
            (request) => __awaiter(void 0, void 0, void 0, function* () {
                const { idToken } = yield AuthService.currentSession();
                request.headers.set('Authorization', `Bearer ${idToken.toString()}`);
            })
        ]
    }
});
