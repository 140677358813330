import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSummaryStore } from '@entities/summary';
import { useTranslation } from 'react-i18next';
export function useProjectSummary() {
    const { projectId } = useParams();
    const { t } = useTranslation();
    const { data, fetchSummary, reset } = useSummaryStore();
    useEffect(() => {
        if (projectId) {
            fetchSummary(projectId);
        }
        return () => {
            reset();
        };
    }, [fetchSummary, projectId, t]);
    return {
        data
    };
}
