import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { MdCheck } from 'react-icons/md';
import { ErrorIcon } from '@shared/ui/icons';
import { useTranslationProject } from '@shared/lib/hooks';
import styles from './DocumentFields.module.scss';
import { useDocumentStore, DocumentTypeSelectField } from '@entities/project/document';
export function DocumentTypeField() {
    const { tproject } = useTranslationProject();
    const { page } = useDocumentStore();
    const [selectedPageType, setSelectedPageType] = React.useState();
    useEffect(() => {
        setSelectedPageType(page === null || page === void 0 ? void 0 : page.predictions.type);
    }, [page]);
    return (_jsx(Box, { className: styles.docType, children: _jsx(Box, { mt: 20, mb: 16, children: _jsxs(Box, { className: styles.formElement, children: [_jsxs(Box, { display: 'flex', alignItems: 'center', gap: 8, children: [selectedPageType !== 'undefined' ? _jsx(MdCheck, { size: 18 }) : _jsx(ErrorIcon, {}), _jsx(Box, { children: tproject('Document type') })] }), _jsx(DocumentTypeSelectField, { selectedPageType: selectedPageType, setSelectedPageType: setSelectedPageType })] }) }) }));
}
