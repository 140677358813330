export var DocumentGroupedResultRowType;
(function (DocumentGroupedResultRowType) {
    DocumentGroupedResultRowType["unassigned"] = "unassigned";
    DocumentGroupedResultRowType["grouped"] = "grouped";
})(DocumentGroupedResultRowType || (DocumentGroupedResultRowType = {}));
export var GroupingRowType;
(function (GroupingRowType) {
    GroupingRowType["grouped"] = "Grouped By IC";
    GroupingRowType["ungrouped"] = "No grouping";
})(GroupingRowType || (GroupingRowType = {}));
