var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { vdaAPI } from '@shared/lib/api';
import { getContentDisposition, getFilenameContentType } from '@shared/lib/utils';
import { plainToInstance } from 'class-transformer';
import { DocumentModel } from '../../models/Document.model';
export class DocumentService {
    static getDocument(projectId, documentId) {
        return __awaiter(this, void 0, void 0, function* () {
            const documentRes = (yield vdaAPI
                .get(`projects/${projectId}/documents/${documentId}`)
                .json());
            return plainToInstance(DocumentModel, documentRes);
        });
    }
    static getPage(projectId, documentId, pageId) {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield vdaAPI
                .get(`projects/${projectId}/documents/${documentId}/pages/${pageId}`)
                .json());
        });
    }
    static updatePage(projectId, documentId, pageId, finalize, predictions) {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield vdaAPI
                .put(`projects/${projectId}/documents/${documentId}/pages/${pageId}?finalize=${finalize}`, {
                json: predictions
            })
                .json());
        });
    }
    static createDocument(filename, projectId) {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield vdaAPI
                .post(`projects/${projectId}/documents`, {
                headers: {
                    'Content-Type': getFilenameContentType(filename),
                    'Content-Disposition': getContentDisposition(filename),
                    Accept: 'application/json'
                }
            })
                .json());
        });
    }
    static uploadDocument(url, file) {
        return __awaiter(this, void 0, void 0, function* () {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', url);
            xhr.setRequestHeader('Content-Type', getFilenameContentType(file.name));
            xhr.setRequestHeader('Content-Disposition', getContentDisposition(file.name));
            return new Promise((resolve, reject) => {
                xhr.send(file);
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        resolve(true);
                    }
                    else {
                        reject(new Error(xhr.statusText));
                    }
                };
            });
        });
    }
    static getDocuments(projectId) {
        return __awaiter(this, void 0, void 0, function* () {
            const documents = (yield vdaAPI.get(`projects/${projectId}/documents`).json());
            return plainToInstance(DocumentModel, documents);
        });
    }
    static removeDocument(projectId, documentId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield vdaAPI.delete(`projects/${projectId}/documents/${documentId}`).json();
        });
    }
}
