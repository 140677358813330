import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ToastService } from '@shared/lib/services';
import { UserService } from '../services/User.service';
import { makeListQueryKey } from './utils/user';
import { useTranslationUser } from '@shared/lib/hooks';
import { useCurrentProject } from '@entities/project';
export function useUsers() {
    const { project } = useCurrentProject();
    const { tuser } = useTranslationUser();
    const { data, isLoading, isError, error } = useQuery({
        queryKey: makeListQueryKey(project.id),
        queryFn: () => UserService.getUsers(project.id).catch(() => {
            throw new Error(tuser('Error occurred while loading the Users.'));
        })
    });
    const errorMessage = isError && error.message;
    useEffect(() => {
        if (errorMessage) {
            ToastService.error(errorMessage);
        }
    }, [errorMessage]);
    return {
        users: data || [],
        isLoading
    };
}
