import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './ProjectHeader.module.scss';
import { UploadFileButton } from '@features/uploadFile/ui/UploadFileButton';
import { DownloadButton } from '@pages/Result/ui/Download/DownloadButton';
import { InviteUser } from '@features/inviteUser';
import { ProjectTabsEnum } from '../lib/types/ProjectTabTypes';
import { useAuth } from '@entities/auth';
export function ProjectNavigation({ activeTab }) {
    const { user } = useAuth();
    return (_jsxs("div", { className: styles.buttonsContainer, children: [activeTab === ProjectTabsEnum.DOCUMENTS ? _jsx(UploadFileButton, {}) : null, activeTab === ProjectTabsEnum.USERS && (user === null || user === void 0 ? void 0 : user.hasAdminRole) ? _jsx(InviteUser, {}) : null, activeTab === ProjectTabsEnum.SUMMARY ? _jsx(DownloadButton, {}) : null] }));
}
