import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import Logo from '@shared/assets/images/conxai_logo.png';
import { Box } from '@mui/material';
import styles from '@pages/layout/DefaultLayout/ui/Layout.module.scss';
import { Typography } from '@shared/ui/Typography';
import { useTranslation } from 'react-i18next';
export function Footer() {
    const { t } = useTranslation();
    return (_jsxs(Box, { className: styles.footer, children: [_jsxs(Stack, { spacing: 8, direction: "row", children: [_jsx(Typography, { color: "white", variant: "body1", component: "a", href: "https://www.conxai.com/privacy-policy", target: "_blank", children: t('Privacy Policy') }), _jsx(Typography, { variant: "body1", color: "white", children: "\u2022" }), _jsx(Typography, { color: "white", variant: "body1", component: "a", href: "https://www.conxai.com/terms-of-service", target: "_blank", children: t('Terms of Service') }), _jsx(Typography, { variant: "body1", color: "white", children: "\u2022" }), _jsxs(Typography, { variant: "body1", color: "white", children: ["\u00A9 ", new Date().getFullYear(), " ", t('All rights reserved')] })] }), _jsx("img", { className: styles.logo, src: Logo })] }));
}
