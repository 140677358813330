import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom/client';
import '@shared/scss/main.scss';
import './i18n/config';
import 'reflect-metadata';
import App from './ui/App';
import '@shared/assets/styles/font.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
// <React.StrictMode>
_jsx(App, {})
// </React.StrictMode>,
);
