import { useQuery } from '@tanstack/react-query';
import { ProjectService } from '@entities/project';
import { ToastService } from '@shared/lib/services';
import { useEffect } from 'react';
import { LIST_QUERY_KEY } from './constants/project';
export function useGetProjects() {
    const { data, isFetching, isError, error } = useQuery({
        queryKey: LIST_QUERY_KEY,
        queryFn: ProjectService.getProjects,
        placeholderData: []
    });
    const errorMessage = isError && error.message;
    useEffect(() => {
        if (errorMessage) {
            ToastService.error(errorMessage);
        }
    }, [errorMessage]);
    return {
        projects: isError ? [] : data,
        isError,
        isFetching
    };
}
