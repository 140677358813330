import { FieldProperty } from '@shared/types';
export const fields = [
    {
        label: 'Company name',
        property: FieldProperty.company_name
    },
    {
        label: 'Shipping date',
        property: FieldProperty.shipping_date
    },
    {
        label: 'Certificate number',
        property: FieldProperty.certificate_number
    },
    {
        label: 'Document number',
        property: FieldProperty.document_number
    },
    {
        label: 'Total amount',
        property: FieldProperty.total_amount
    }
];
