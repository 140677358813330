import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { DocumentPreparing, DocumentPreview, DocumentReview } from '@widgets/document';
import stylesDocument from '@widgets/document/ui/document.module.scss';
import { isDocumentFetched, useDocument } from '@entities/project/document';
export function DocumentReviewPage() {
    useDocument();
    const isFetched = isDocumentFetched();
    if (!isFetched) {
        return _jsx(DocumentPreparing, {});
    }
    return (_jsxs(Box, { className: stylesDocument.reviewContainer, children: [_jsx(DocumentReview, {}), _jsx(DocumentPreview, {})] }));
}
