import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { UploadFileDialog } from '../UploadFileDialog';
import { useTranslationProject } from '@shared/lib/hooks';
import { Button } from '@shared/ui/Button';
import { useBoolean } from '@shared/lib/hooks';
export function UploadFileButton() {
    const { tproject } = useTranslationProject();
    const [isUploadFileDialogOpen, , showUploadFileDialog, hideUploadFileDialog] = useBoolean(false);
    return (_jsxs(_Fragment, { children: [_jsx(Button, { variant: "contained", onClick: showUploadFileDialog, children: tproject('Upload File') }), isUploadFileDialogOpen && _jsx(UploadFileDialog, { onClose: hideUploadFileDialog })] }));
}
