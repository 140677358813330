import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Header from '../../Header/Header';
import { Box } from '@mui/material';
import styles from './Layout.module.scss';
import { Outlet } from 'react-router-dom';
import { Footer } from '../../Footer/Footer';
import { ProtectedLayoutWrapper } from '@entities/auth';
export function Layout() {
    return (_jsx(ProtectedLayoutWrapper, { children: _jsxs(Box, { className: styles.layout, children: [_jsx(Header, {}), _jsx(Box, { className: styles.layout, children: _jsx(Outlet, {}) }), _jsx(Footer, {})] }) }));
}
