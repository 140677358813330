export function getFilenameContentType(filename) {
    switch (filename.split('.')[filename.split('.').length - 1]) {
        case 'png': {
            return 'image/png';
        }
        case 'jpg':
        case 'jpeg': {
            return 'image/jpeg';
        }
        case 'pdf': {
            return 'application/pdf';
        }
        default: {
            return '';
        }
    }
}
