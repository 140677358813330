import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useProjectSummary } from '@entities/project/lib/hooks/useProjectSummary';
import { ProjectSummarySkeleton } from './ProjectSummarySkeleton';
import { ResultsTable } from '@pages/Result/ui/ResultsTable';
import styles from './ProjectSummary.module.scss';
import { useTranslationProject } from '@shared/lib/hooks';
export function ProjectSummary() {
    const { tproject } = useTranslationProject();
    const { data } = useProjectSummary();
    if (!data) {
        return _jsx(ProjectSummarySkeleton, {});
    }
    return (_jsxs(_Fragment, { children: [data.length === 0 && (_jsx("div", { className: styles.containerEmpty, children: tproject('Summary table will be available after at least one document is uploaded and validated') })), _jsx("div", { className: styles.container, children: data.length > 0 && _jsx(ResultsTable, { document: undefined, data: data, documentId: '' }) })] }));
}
