export const groupErrorsByProperty = (errors) => {
    const groupedErrors = new Map();
    for (const error of errors) {
        if (!groupedErrors.has(error.property)) {
            groupedErrors.set(error.property, new Map());
        }
        const propertyMap = groupedErrors.get(error.property);
        const existingErrors = propertyMap.get(String(error.index)) || [];
        propertyMap.set(String(error.index), [...existingErrors, error]);
    }
    return groupedErrors;
};
