var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Expose, Transform, Type } from 'class-transformer';
import LocationModel from './Location.model';
import { parseISO, format } from 'date-fns';
import { dateFormat } from '@shared/lib/constants';
export default class ProjectModel {
}
__decorate([
    Expose({ name: 'uuid' })
], ProjectModel.prototype, "id", void 0);
__decorate([
    Expose({ name: 'name' })
], ProjectModel.prototype, "name", void 0);
__decorate([
    Expose({ name: 'company_uuid' })
], ProjectModel.prototype, "companyUuid", void 0);
__decorate([
    Expose({ name: 'settings' }),
    Type(() => LocationModel),
    Transform(({ value }) => {
        if (value && value.location) {
            return value.location;
        }
        return value;
    })
], ProjectModel.prototype, "location", void 0);
__decorate([
    Expose({ name: 'created_at' }),
    Transform(({ value }) => {
        if (!value)
            return value;
        try {
            return format(parseISO(value), dateFormat);
        }
        catch (e) {
            return value;
        }
    })
], ProjectModel.prototype, "createdAt", void 0);
__decorate([
    Expose({ name: 'created_by' })
], ProjectModel.prototype, "createdBy", void 0);
__decorate([
    Expose({ name: 'startedOn' }),
    Type(() => Date)
], ProjectModel.prototype, "startedOn", void 0);
