export const setCookie = (name, value, days) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);
    const cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; expires=${expirationDate.toUTCString()}; path=/`;
    document.cookie = cookieString;
};
export const getCookie = (name) => {
    const cookies = document.cookie
        .split('; ')
        .find(row => row.startsWith(`${encodeURIComponent(name)}=`));
    return cookies ? decodeURIComponent(cookies.split('=')[1]) : null;
};
