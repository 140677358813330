export const ROUTES = {
    PROJECTS: '/',
    PROJECT_DOCUMENTS: '/projects/:projectId/documents',
    PROJECT_USERS: '/projects/:projectId/users',
    PROJECT_SUMMARY: '/projects/:projectId/summary',
    DOCUMENT_REVIEW: '/projects/:projectId/documents/:docId/pages/:pageId',
    DOCUMENT_SUMMARY: '/projects/:projectId/documents/:id/summary',
    UPLOAD: '/project/:projectId',
    PREVIEW: '/projects/:projectId/documents/:id',
    OVERVIEW: '/projects/:projectId/documents/:id/overview'
};
