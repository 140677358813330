import { useParams } from 'react-router-dom';
import { currentProjectStore } from '../state/currentProjectStore';
import { useFindProject } from '../hooks/useFindProject';
import { useEffect } from 'react';
export function useCurrentProject() {
    const { projectId } = useParams();
    const { setCurrentProject } = currentProjectStore();
    const { project, isFetching, isError } = useFindProject(projectId);
    useEffect(() => {
        if (project)
            setCurrentProject(project);
    }, [project, setCurrentProject]);
    return {
        project,
        isFetching,
        isError
    };
}
