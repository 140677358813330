import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, TableCell } from '@mui/material';
import { Tooltip } from '@shared/ui';
import { resultValueNotAppliable } from '../lib/constants/resultValueNotAppliable';
import { useSummaryStore } from '@entities/summary';
import { ResultFieldProperties } from '@shared/types/ResultFieldProperties';
import { CellError } from './CellError';
import styles from './SummaryTable.module.scss';
import classNames from 'classnames';
import { getCellErrors } from '../lib/utils/getCellErrors';
export function ResultsTableReadOnlyCell({ value, index, property }) {
    const { groupedErrors } = useSummaryStore();
    const { errors, hasError } = getCellErrors(groupedErrors, property, index);
    if (property === ResultFieldProperties.filename) {
        return (_jsx(TableCell, { className: styles.resultTableCell, children: _jsx(Tooltip, { title: value, children: _jsx(Box, { className: styles.compact, children: value }) }) }));
    }
    return (_jsxs(TableCell, { className: classNames(styles.resultTableCell, {
            [styles.placeholderData]: value === resultValueNotAppliable,
            [styles.missingData]: hasError
        }), children: [value, hasError ? _jsx(CellError, { errors: errors }) : null] }));
}
