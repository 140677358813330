import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDocumentStore } from '../state/useDocumentStore';
import { useSelectedFieldStore } from '../state/useSelectedFieldStore';
import { useCurrentProject } from '../../../lib/hooks/useCurrentProject';
export function useDocument() {
    const { docId, pageId } = useParams();
    const { fetchDocumentWithPage } = useDocumentStore();
    const { reset: resetSelection } = useSelectedFieldStore();
    const { project } = useCurrentProject();
    useEffect(() => {
        if (docId && pageId && project.id) {
            resetSelection();
            fetchDocumentWithPage(project.id, docId, pageId);
        }
    }, [docId, pageId, project.id]);
}
