import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ThemeProvider } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { IntlProvider } from 'react-intl';
import { RouterProvider } from 'react-router-dom';
import '../config/Amplify';
import theme from '../../shared/theme';
import { ErrorFallback } from '@widgets/errorFallback';
import '../config/Sentry';
import QueryClientProvider from '../providers/QueryClientProvider';
import router from '@app/router';
import { Toaster } from '@shared/ui/Toaster';
const App = () => (_jsx(_Fragment, { children: _jsx(ThemeProvider, { theme: theme, children: _jsx(ErrorBoundary, { FallbackComponent: ErrorFallback, children: _jsx(QueryClientProvider, { children: _jsxs(IntlProvider, { locale: (navigator.languages && navigator.languages[0]) || navigator.language || 'en-US', children: [_jsx(RouterProvider, { router: router }), _jsx(Toaster, {})] }) }) }) }) }));
export default App;
