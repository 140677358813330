var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { api } from '@shared/lib/api/api';
export class DocumentOCRService {
    static getOCR(url) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const data = yield api.get(url).json();
                return prepareOCRData(data);
            }
            catch (e) {
                console.log(e.message);
            }
        });
    }
}
function prepareOCRData(data) {
    const ocr = [];
    data.texts.forEach((text, index) => {
        ocr.push({ text, coordinates: data.coordinates[index] });
    });
    ocr.sort((a, b) => a.coordinates[1] !== b.coordinates[1] ? 1 : a.coordinates[0] - b.coordinates[0]);
    return ocr;
}
