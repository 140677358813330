import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import styles from './MRTTable.module.scss';
export function MRTTable({ rows, columns, handleCellClick, loading }) {
    const memoizedColumns = useMemo(() => columns, [columns]);
    return (_jsx(MaterialReactTable, { columns: memoizedColumns, data: rows, state: { isLoading: loading }, enableColumnActions: false, enableColumnFilters: false, enablePagination: false, enableSorting: true, enableStickyHeader: true, muiTableContainerProps: {
            className: styles.tableContainer,
            sx: {
                borderColor: 'var(--color-border)'
            }
        }, muiTableBodyCellProps: ({ cell }) => ({
            onClick: () => handleCellClick && handleCellClick(cell),
            sx: {
                cursor: 'pointer',
                fontSize: '1.6rem',
                color: 'var(--color-text-primary)',
                fontWeight: 400,
                fontStyle: 'normal',
                maxWidth: '20rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                height: '5.2rem',
                '&:focus, &:focus-within': {
                    outline: 'unset'
                }
            }
        }), muiTableHeadCellProps: {
            sx: {
                backgroundColor: 'var(--color-table-header)',
                color: 'var(--color-text-secondary)',
                fontSize: '1.2rem',
                fontWeight: 600,
                height: '5.6rem',
                paddingTop: '2rem',
                '&:focus, &:focus-within': {
                    outline: 'unset'
                },
                '& .MuiButtonBase-root': {
                    opacity: 0
                },
                '&:hover .MuiButtonBase-root': {
                    opacity: 1
                }
            }
        }, muiTableBodyRowProps: {
            sx: {
                backgroundColor: '#f8f8f8',
                '&:last-child td': {
                    borderBottom: 'none'
                }
            }
        }, muiTableHeadRowProps: {
            sx: {
                '& th': {
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                    '&:last-child': {
                        borderRight: 'none'
                    }
                }
            }
        }, muiTableBodyProps: {
            sx: {
                '& td': {
                    borderRight: '1px solid var(--color-border)',
                    position: 'relative',
                    '&:last-child': {
                        borderRight: 'none'
                    }
                },
                '& tr:hover td::after': {
                    backgroundColor: 'var(--color-table-header)'
                }
            }
        }, enableTopToolbar: false, enableBottomToolbar: false }));
}
