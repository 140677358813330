var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fetchAuthSession, signInWithRedirect, signOut } from 'aws-amplify/auth';
import { ADMIN_COGNITO_KEY } from '@shared/lib/constants';
export class AuthService {
    static currentUser() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { idToken } = yield AuthService.currentSession();
                const { 'cognito:username': id, email, 'cognito:groups': groups } = idToken.payload;
                const cognitoGroups = groups;
                return {
                    id: id,
                    email: email,
                    groups: cognitoGroups,
                    hasAdminRole: cognitoGroups.includes(ADMIN_COGNITO_KEY)
                };
            }
            catch (error) {
                console.log('Not signed in', { error });
                yield signInWithRedirect();
            }
        });
    }
    static currentSession() {
        return __awaiter(this, arguments, void 0, function* (forceRefresh = false) {
            var _a;
            try {
                const { accessToken, idToken } = (_a = (yield fetchAuthSession({ forceRefresh })).tokens) !== null && _a !== void 0 ? _a : {};
                if (!accessToken || !idToken) {
                    yield signInWithRedirect();
                }
                return { idToken };
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    static logout() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield signOut();
            }
            catch (error) {
                console.log('error signing out: ', error);
            }
        });
    }
}
