import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { Tooltip } from '@shared/ui';
import { ErrorIcon, WarningIcon } from '@shared/ui/icons';
import { DocumentResultErrorTypes } from '@shared/types/DocumentResultError';
import { getErrorMessages } from '../lib/utils/getErrorMessages';
import styles from './SummaryTable.module.scss';
export function CellError({ errors }) {
    const errorTypePriority = errors.some(error => error.type === DocumentResultErrorTypes.error);
    const errorMessage = getErrorMessages(errors);
    return (_jsx(Box, { className: styles.cellError, children: _jsx(Tooltip, { title: errorMessage, placement: 'top-end', children: _jsx("span", { children: errorTypePriority ? _jsx(ErrorIcon, {}) : _jsx(WarningIcon, {}) }) }) }));
}
