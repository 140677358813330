import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QueryClient, QueryClientProvider as TanStackQueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
const oneHourInMs = 1000 * 60 * 60;
const client = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: true,
            staleTime: oneHourInMs,
            retry: false
        }
    }
});
export default function QueryClientProvider({ children }) {
    return (_jsxs(TanStackQueryClientProvider, { client: client, children: [children, _jsx(ReactQueryDevtools, { initialIsOpen: false })] }));
}
