import { useTranslationProject } from '@shared/lib/hooks';
import { UserService } from '../services/User.service';
import { makeListQueryKey } from './utils/user';
import { useMutation } from '@shared/lib/hooks';
import { useCurrentProject } from '@entities/project';
export function useRemoveUser() {
    const { project } = useCurrentProject();
    const { tproject } = useTranslationProject();
    const listQueryOptions = { queryKey: makeListQueryKey(project.id) };
    const { mutate: removeUser, isLoading: isRemoving, isSuccess: isRemoved } = useMutation({
        mutationFn: (userId) => UserService.removeUser(project.id, userId).catch(() => {
            throw new Error(tproject('Error occurred. Please try to delete again.'));
        }),
        options: listQueryOptions
    });
    return {
        removeUser,
        isRemoving,
        isRemoved
    };
}
