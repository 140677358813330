import { PagePredictionProperties } from '../../../../../shared/types';
import { isNumericTableField } from './isNumericTableField';
export function preparePageDataOnSave(data) {
    data[PagePredictionProperties.total_amount] = Object.assign(Object.assign({}, data[PagePredictionProperties.total_amount]), { prediction: prepareTotalAmountField(data[PagePredictionProperties.total_amount].prediction) });
    Object.keys(data.table).forEach((key) => {
        const tableProperty = key;
        if (isNumericTableField(tableProperty)) {
            data.table[tableProperty].values = data.table[tableProperty].values.map(val => Number(val));
        }
    });
    return data;
}
function prepareTotalAmountField(value) {
    const onlyDigits = parseInt(value);
    return isNaN(onlyDigits) ? null : onlyDigits;
}
