import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
import classNames from 'classnames';
import styles from './DocumentTypeSelectField.module.scss';
import { useDocumentStore } from '../lib/state/useDocumentStore';
import { documentTypes } from '../lib/constants/documentTypes';
import { useTranslation } from 'react-i18next';
import { PageTypes } from '@shared/types';
export function DocumentTypeSelectField({ selectedPageType, setSelectedPageType }) {
    const { page, updatePage } = useDocumentStore();
    const { t } = useTranslation();
    const [isFocused, setIsFocused] = useState(false);
    const handleMenuItemClick = (_, pageType) => {
        setSelectedPageType(pageType);
        if (page) {
            updatePage(Object.assign(Object.assign({}, page), { predictions: Object.assign(Object.assign({}, page === null || page === void 0 ? void 0 : page.predictions), { type: pageType }) }));
        }
    };
    return (_jsx(FormControl, { className: styles.docTypeFormControl, children: _jsx(Select, { className: classNames(styles.styledSelectField, {
                [styles['Mui-focused']]: isFocused
            }), onFocus: () => setIsFocused(true), onBlur: () => setIsFocused(false), value: page && page.predictions ? t(documentTypes[page.predictions.type]) : '', displayEmpty: true, children: Object.keys(PageTypes).map(key => {
                // @ts-ignore
                const docType = key;
                return (_jsx(MenuItem, { className: styles.item, selected: docType === selectedPageType, value: t(documentTypes[docType]), onClick: event => {
                        handleMenuItemClick(event, docType);
                    }, children: t(documentTypes[docType]) }, `select-certificate-type-${docType}`));
            }) }) }));
}
