import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MdAdd, MdFitScreen, MdRemove } from 'react-icons/md';
import { TransformComponent } from 'react-zoom-pan-pinch';
import { useLazyImageLoad } from '@shared/lib/hooks/useLazyImageLoad';
import { useDocumentStore, useSelectedFieldStore, isGeneralField } from '@entities/project/document';
import stylesDocument from '../document.module.scss';
import { DocumentImageOCR } from '@entities/documentOCR';
export function Preview({ panPinchState, imageWrapperRef, zoomIn, zoomOut, resetTransform }) {
    const { field, index: fieldIndex } = useSelectedFieldStore();
    const { page } = useDocumentStore();
    const { isFetched } = useLazyImageLoad(page === null || page === void 0 ? void 0 : page.image_high);
    const imageRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: undefined, height: undefined });
    const [boundaryBox, setBoundaryBox] = useState({
        top: 0,
        left: 0,
        width: 0,
        height: 0
    });
    useEffect(() => {
        resetTransform();
    }, [page === null || page === void 0 ? void 0 : page.image_low]);
    useEffect(() => {
        if (isFetched) {
            recalculateDimensions();
            window.onresize = () => {
                recalculateDimensions();
            };
        }
        return () => {
            window.onresize = null;
        };
        // eslint-disable-next-line
    }, [isFetched]);
    const selectedFieldCoordinates = useMemo(() => {
        var _a;
        let coordinates;
        const fallBackCordinates = new Array(4).fill(0);
        if (field) {
            if (isGeneralField(field)) {
                coordinates = (_a = page === null || page === void 0 ? void 0 : page.predictions[field.property]) === null || _a === void 0 ? void 0 : _a.coordinates;
                return coordinates && coordinates.length > 0 && coordinates[0].map(Number).length
                    ? coordinates[0].map(Number)
                    : fallBackCordinates;
            }
            else {
                if (!(page === null || page === void 0 ? void 0 : page.predictions.table[field.property])) {
                    return fallBackCordinates;
                }
                coordinates = page === null || page === void 0 ? void 0 : page.predictions.table[field.property].coordinates;
                return coordinates &&
                    coordinates.length > 0 &&
                    fieldIndex !== undefined &&
                    coordinates[fieldIndex].map(Number).length
                    ? coordinates[fieldIndex].map(Number)
                    : fallBackCordinates;
            }
        }
    }, [page, field, fieldIndex]);
    useEffect(() => {
        updateBoundaryBox();
        // eslint-disable-next-line
    }, [dimensions, selectedFieldCoordinates, panPinchState]);
    const recalculateDimensions = () => {
        var _a, _b;
        if (page && isFetched) {
            const width = ((_a = imageRef.current) === null || _a === void 0 ? void 0 : _a.width) || 0;
            const height = ((_b = imageRef.current) === null || _b === void 0 ? void 0 : _b.height) || 0;
            setDimensions({ width, height });
        }
    };
    const updateBoundaryBox = () => {
        if (selectedFieldCoordinates &&
            selectedFieldCoordinates.length > 0 &&
            dimensions.width &&
            dimensions.height) {
            setBoundaryBox({
                top: selectedFieldCoordinates[1] * dimensions.height,
                left: selectedFieldCoordinates[0] * dimensions.width,
                width: selectedFieldCoordinates[2] * dimensions.width,
                height: selectedFieldCoordinates[3] * dimensions.height
            });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: stylesDocument.actions, children: [_jsx(Button, { size: 'small', variant: 'outlined', onClick: () => zoomIn(), startIcon: _jsx(MdAdd, {}) }), _jsx(Button, { size: 'small', variant: 'outlined', onClick: () => zoomOut(), startIcon: _jsx(MdRemove, {}) }), _jsx(Button, { size: 'small', variant: 'outlined', onClick: () => resetTransform(), startIcon: _jsx(MdFitScreen, {}) })] }), _jsxs(TransformComponent, { children: [_jsx("img", { ref: imageRef, src: isFetched ? page === null || page === void 0 ? void 0 : page.image_high : page === null || page === void 0 ? void 0 : page.image_low, alt: page === null || page === void 0 ? void 0 : page.image_low }), isFetched && boundaryBox.width !== 0 && boundaryBox.height !== 0 ? (_jsx(Box, { className: stylesDocument.boundaryBox, top: boundaryBox.top, left: boundaryBox.left, width: boundaryBox.width, height: boundaryBox.height })) : null, _jsx(DocumentImageOCR, { dimensions: dimensions, ocrUrl: page.ocr })] })] }));
}
