const palette = {
    primary: {
        main: '#E6002D',
        dark: '#E6002D',
        light: '#FEB5C4'
    },
    secondary: {
        main: '#101828',
        dark: '#101828',
        light: '#101828'
    },
    info: {
        main: '#00A6F9',
        dark: '#00A6F9',
        light: '#00A6F9'
    },
    success: {
        main: '#00B955',
        dark: '#00B955',
        light: '#31BC4F'
    },
    error: {
        main: '#E6002D',
        light: '#FFEBE9'
    },
    grey: {
        50: '#F9FAFB',
        100: '#223354',
        200: '#EAECF0',
        300: '#C7CDCB',
        400: '#9CA3AF',
        500: '#667085',
        600: '#1D3557',
        700: '#344054',
        800: '#363434',
        900: '#101828'
    }
};
export default palette;
