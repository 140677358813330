var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect, useCallback } from 'react';
import { useGetProjects, ProjectService } from '@entities/project';
export function useGetProjectsData() {
    const { projects } = useGetProjects();
    const [updatedProjects, setUpdatedProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const getDocCount = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            const response = yield ProjectService.getProjectsDocumentCount();
            const data = projects.map(item => (Object.assign(Object.assign({}, item), { 
                // @ts-ignore
                docCount: response[item.id] || 0 })));
            setUpdatedProjects(data);
        }
        catch (error) {
            console.error('Failed to fetch document count:', error);
        }
        finally {
            setIsLoading(false);
        }
    }), [projects]);
    useEffect(() => {
        if (projects.length > 0) {
            getDocCount();
        }
    }, [projects, getDocCount]);
    return { data: updatedProjects, isLoading };
}
