import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress } from '@mui/material';
export function DocumentStatusLoader(props) {
    return (_jsxs(Box, { sx: { position: 'relative', display: 'flex' }, children: [_jsx(CircularProgress, Object.assign({ variant: "determinate", sx: {
                    color: '#DADADA'
                }, size: 23, thickness: 8 }, props, { value: 100 })), _jsx(CircularProgress, Object.assign({ variant: "indeterminate", disableShrink: true, sx: {
                    color: '#585858',
                    animationDuration: '1s',
                    position: 'absolute',
                    left: 0
                }, size: 23, thickness: 8 }, props))] }));
}
