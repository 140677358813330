import { useMutation } from '@shared/lib/hooks';
import { ProjectService } from '../services/Project.service';
import { useTranslationProject } from '@shared/lib/hooks';
import { LIST_QUERY_KEY } from './constants/project';
import { useCallback } from 'react';
import { currentProjectStore } from '../state/currentProjectStore';
import { plainToInstance } from 'class-transformer';
import ProjectModel from '../../model/Project.model';
const QUERY_OPTIONS = {
    queryKey: LIST_QUERY_KEY,
    skipInvalidation: true
};
export function useUpdateProject(onSuccess, id) {
    const { project } = currentProjectStore();
    const { tproject } = useTranslationProject();
    const updateProject = useCallback((project) => ProjectService.updateProject(id, project).catch(() => {
        throw new Error(tproject('Error occurred. Please try to upload an image again.'));
    }), [tproject, project, id]);
    const { mutate: update, isLoading: isUpdating, isSuccess: isUpdated } = useMutation({
        mutationFn: updateProject,
        onSuccess: (client, data) => {
            const projectId = data.id;
            const projects = client.getQueryData(LIST_QUERY_KEY);
            const updatedProjects = projects.reduce((acc, project) => {
                if (project.id === projectId) {
                    const updatedProject = plainToInstance(ProjectModel, Object.assign(Object.assign({}, project), data), { ignoreDecorators: true });
                    acc.push(updatedProject);
                }
                else {
                    acc.push(project);
                }
                return acc;
            }, []);
            client.setQueryData(LIST_QUERY_KEY, updatedProjects);
            onSuccess(project, data);
        },
        options: QUERY_OPTIONS
    });
    return {
        update,
        isUpdating,
        isUpdated
    };
}
