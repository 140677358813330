import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Divider, Box, Button } from '@mui/material';
import { WarningIcon, ErrorIcon } from '@shared/ui/icons';
import { useTranslation } from 'react-i18next';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { scrollToDocumentRowError } from '@pages/Result/lib/utils/scrollToDocumentRowError';
import styles from './errorList.module.scss';
const ErrorTile = ({ errorDetails, hasDivider }) => {
    const { t } = useTranslation();
    const handleClick = () => {
        scrollToDocumentRowError(`row-has-error ${errorDetails.index}`);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { className: styles.tileContainer, onClick: handleClick, children: [_jsxs(Box, { className: styles.descriptionContainer, children: [(errorDetails === null || errorDetails === void 0 ? void 0 : errorDetails.type) === 'warning' ? (_jsx(WarningIcon, { size: 24, className: styles.icon })) : (_jsx(ErrorIcon, { className: styles.icon, size: 24 })), errorDetails === null || errorDetails === void 0 ? void 0 : errorDetails.message] }), (errorDetails === null || errorDetails === void 0 ? void 0 : errorDetails.index) !== undefined ? (_jsxs(Button, { variant: "text", className: styles.actionButton, children: [_jsx(LocationSearchingIcon, { height: 24 }), " ", t('View')] })) : null] }), hasDivider && _jsx(Divider, { color: "#DADADA", style: { width: '100%' } })] }));
};
export default ErrorTile;
