export function onInputPasteText(e, excludeSymbols = []) {
    const pasteValue = preparePasteValue(e, excludeSymbols);
    const target = e.target;
    let resultString = target.value;
    resultString =
        resultString.slice(0, target.selectionStart) +
            pasteValue +
            resultString.slice(target.selectionEnd);
    return resultString;
}
function preparePasteValue(e, excludeSymbols = []) {
    let pasteValue = e.clipboardData.getData('text').split('\n').join('');
    excludeSymbols.forEach(val => {
        pasteValue = pasteValue.replace(val, '');
    });
    return pasteValue;
}
