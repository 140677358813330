import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { GroupingRowType } from '../lib/types/DocumentResultRowType';
import { useTranslation } from 'react-i18next';
import styles from './SummaryTable.module.scss';
const DataGroupToggle = ({ groupingStatus, handleChangeGroupingStatus }) => {
    const { t } = useTranslation();
    const handleChange = (event) => {
        handleChangeGroupingStatus(event.target.value);
    };
    return (_jsxs(Select, { value: groupingStatus, onChange: handleChange, size: "small", className: styles.styledSelect, children: [_jsx(MenuItem, { className: styles.item, value: GroupingRowType.ungrouped, children: t(GroupingRowType.ungrouped) }), _jsx(MenuItem, { className: styles.item, value: GroupingRowType.grouped, children: t(GroupingRowType.grouped) })] }));
};
export default DataGroupToggle;
