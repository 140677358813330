var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslationProject } from '@shared/lib/hooks';
import { ROUTES } from '@app/constants';
import styles from './Preview.module.scss';
import { DocumentService } from '@entities/project/document/lib/services/Document.service';
import { useCurrentProject } from '@entities/project';
let timerId;
export const DocumentPreview = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { project } = useCurrentProject();
    const { tproject } = useTranslationProject();
    const handleProgress = () => __awaiter(void 0, void 0, void 0, function* () {
        if (id) {
            const result = yield DocumentService.getPage(project.id, id, `0`);
            if (result.status !== 'processing') {
                navigate(ROUTES.DOCUMENT_REVIEW.replace(':projectId', project.id)
                    .replace(':docId', id)
                    .replace(':pageId', '0'));
            }
            clearInterval(timerId);
            timerId = setInterval(() => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    const result = yield DocumentService.getPage(project.id, id, `0`);
                    if (result.status !== 'processing') {
                        clearInterval(timerId);
                        navigate(ROUTES.DOCUMENT_REVIEW.replace(':projectId', project.id)
                            .replace(':docId', id)
                            .replace(':pageId', '0'));
                    }
                }
                catch (e) {
                    clearInterval(timerId);
                }
            }), 3000);
        }
    });
    useEffect(() => {
        handleProgress();
        // eslint-disable-next-line
    }, [id]);
    useEffect(() => {
        return () => {
            clearInterval(timerId);
        };
        // eslint-disable-next-line
    }, []);
    return (_jsx(Box, { className: styles.processingContainer, children: _jsxs(Box, { className: styles.file, children: [_jsx(CircularProgress, { size: 48 }), _jsx(Box, { children: tproject('Processing. Please wait...') }), _jsx(Box, { style: { fontWeight: 'normal' }, children: tproject('It can take 1—2 minutes, depending on number of pages in the file') })] }) }));
};
