import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { DownloadButton } from './Download/DownloadButton';
import { Typography } from '@shared/ui/Typography';
import styles from './SummaryTable.module.scss';
import { useTranslation } from 'react-i18next';
export function Header() {
    const { t } = useTranslation();
    return (_jsx(_Fragment, { children: _jsxs(Box, { className: styles.headerToolbar, children: [_jsx(Typography, { variant: 'h1', children: t('Extraction Results') }), _jsx(DownloadButton, {})] }) }));
}
