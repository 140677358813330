var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { customerAPI, vdaAPI } from '@shared/lib/api';
import ProjectModel from '@entities/project/model/Project.model';
import { plainToInstance } from 'class-transformer';
export class ProjectService {
    static getProjects() {
        return __awaiter(this, void 0, void 0, function* () {
            const projects = (yield customerAPI.get('projects').json());
            return plainToInstance(ProjectModel, projects);
        });
    }
    static getProjectsDocumentCount() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield vdaAPI.get('projects').json();
        });
    }
    static updateProject(projectId, project) {
        return __awaiter(this, void 0, void 0, function* () {
            const updatedProject = (yield customerAPI
                .put(`project`, {
                json: { uuid: projectId, name: project.name, settings: { location: { name: project.location } } }
            })
                .json());
            return plainToInstance(ProjectModel, updatedProject);
        });
    }
    static createProject(project) {
        return __awaiter(this, void 0, void 0, function* () {
            const createdProject = (yield customerAPI
                .post(`project`, {
                json: {
                    uuid: process.env.COMPANY_UUID,
                    name: project.name,
                    settings: { location: { name: project.location } }
                }
            })
                .json());
            return plainToInstance(ProjectModel, createdProject);
        });
    }
}
