import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslationProject } from '@shared/lib/hooks';
import { useBoolean } from '@shared/lib/hooks';
import { Button } from '@mui/material';
import { CreateProjectDialog } from '../CreateProjectDialog';
export function AddProjectButton() {
    const { tproject } = useTranslationProject();
    const [isCreateProjectDialogOpen, , showCreateProjectDialog, hideCreateProjectDialog] = useBoolean(false);
    return (_jsxs(_Fragment, { children: [_jsx(Button, { variant: "contained", onClick: showCreateProjectDialog, children: tproject('Add project') }), isCreateProjectDialogOpen && _jsx(CreateProjectDialog, { onClose: hideCreateProjectDialog })] }));
}
