import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Checkbox, Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MdAdd, MdDelete } from 'react-icons/md';
import { PagePredictionProperties, PageTypes, TableFieldProperty } from '@shared/types';
import { calcTableRowCount, onInputPasteText, tableFields, useDocumentStore, useReviewTableKeys, useSelectedFieldStore, useUpdateTable } from '@entities/project/document';
import styles from './TableDataFields.module.scss';
import classNames from 'classnames';
import { checkIfPageHasTableOnly } from '@entities/document';
import { useTranslation } from 'react-i18next';
import { useTranslationProject } from '@shared/lib/hooks';
import { icTableColumnsWidth } from '@features/document/lib/constants/icTableColumnWidth';
import { tableColumnsWidth } from '@features/document/lib/constants/tableColumnWidth';
import { icTableFields } from '@entities/project/document/lib/constants/icTableFields';
export function TableDataFields({ selectedRef }) {
    const { addNewRow, updateCellValue } = useUpdateTable();
    const { updatePage, page, activePageIndex } = useDocumentStore();
    const { t } = useTranslation();
    const { tproject } = useTranslationProject();
    useReviewTableKeys();
    const { field, index: fieldIndex, selectField, reset: resetFieldSelection } = useSelectedFieldStore();
    const [selectedTableRowIds, setSelectedTableRowIds] = useState([]);
    const tableRowCount = calcTableRowCount();
    const isPageHasTableOnly = checkIfPageHasTableOnly(page.predictions.type);
    useEffect(() => {
        if (!field && page && isPageHasTableOnly) {
            selectField(tableFields[0], 0);
        }
    }, [page, field, isPageHasTableOnly]);
    useEffect(() => {
        if (isPageHasTableOnly) {
            selectField(tableFields[0], 0);
            if (tableRowCount === 0) {
                handleAddTableRow();
            }
        }
    }, [isPageHasTableOnly]);
    useEffect(() => {
        resetTableFieldsSelection();
    }, [activePageIndex]);
    const handleAddTableRow = () => {
        addNewRow();
    };
    const handleToggleTableRow = (e, index) => {
        if (e.target.checked) {
            setSelectedTableRowIds([...selectedTableRowIds, index]);
        }
        else {
            setSelectedTableRowIds(selectedTableRowIds.filter(id => id !== index));
        }
    };
    const handleDeleteSelectedTableRows = () => {
        if (page) {
            let table = {};
            const tableEntries = Object.entries(page.predictions.table);
            for (let i = 0; i < tableEntries.length; i++) {
                table = Object.assign(Object.assign({}, table), { [tableEntries[i][0]]: {
                        coordinates: tableEntries[i][1].coordinates.filter((_, idx) => !selectedTableRowIds.includes(idx)),
                        values: tableEntries[i][1].values.filter((_, idx) => !selectedTableRowIds.includes(idx))
                    } });
            }
            updatePage(Object.assign(Object.assign({}, page), { predictions: Object.assign(Object.assign({}, page.predictions), { table }) }));
            setSelectedTableRowIds([]);
            resetFieldSelection();
        }
    };
    const getTableFieldValue = (field, index) => {
        if (!(page === null || page === void 0 ? void 0 : page.predictions.table[field.property])) {
            return undefined;
        }
        const values = page === null || page === void 0 ? void 0 : page.predictions.table[field.property].values;
        return values ? (values[index] === null ? '' : values[index]) : undefined;
    };
    const handleTableItemClick = (e, selectedField) => {
        selectField(selectedField.item, selectedField.index);
        if (document.activeElement !== e.currentTarget.lastChild) {
            e.currentTarget.lastChild.select();
        }
    };
    const handleTableFieldChange = (val) => {
        updateCellValue(val, fieldIndex);
    };
    const tableWidths = page.predictions[PagePredictionProperties.type] === PageTypes.certificate
        ? icTableColumnsWidth
        : tableColumnsWidth;
    const defaultTableFields = page.predictions[PagePredictionProperties.type] === PageTypes.certificate
        ? icTableFields
        : tableFields;
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, { className: styles.tableHeader, children: [_jsx(Stack, { mb: 5, ml: 13, direction: "row", alignItems: "center", justifyContent: "space-between", children: selectedTableRowIds.length === 0 ? (_jsxs("div", { className: styles.rowsTitle, children: [tableRowCount, " ", tproject('rows on the page')] })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.rowsTitle, children: [selectedTableRowIds.length, " ", tproject('of'), " ", tableRowCount, ' ', tproject('rows selected')] }), _jsx(Button, { color: 'primary', variant: "text", size: 'small', startIcon: _jsx(MdDelete, {}), onClick: handleDeleteSelectedTableRows, children: t('Delete') })] })) }), _jsxs(Grid, { className: styles.fieldTable, container: true, columns: tableWidths.total, children: [_jsx(Grid, { item: true, xs: tableWidths.actions, children: _jsx(Box, { className: styles.tableField, children: _jsx(Checkbox, { size: "medium", color: 'primary', indeterminate: tableRowCount > 0 &&
                                            selectedTableRowIds.length > 0 &&
                                            selectedTableRowIds.length < tableRowCount, checked: tableRowCount > 0 && selectedTableRowIds.length === tableRowCount, onChange: () => {
                                            if (selectedTableRowIds.length === 0) {
                                                selectAllTableFields();
                                            }
                                            if (selectedTableRowIds.length > 0) {
                                                resetTableFieldsSelection();
                                            }
                                        } }) }) }), defaultTableFields.map((item, index) => (_jsx(Grid, { item: true, xs: tableWidths[item.property], children: _jsx(Box, { className: styles.tableField, children: t(item.label) }) }, index)))] })] }), _jsxs(Grid, { className: styles.fieldTable, container: true, columns: tableWidths.total, children: [new Array(tableRowCount).fill(0).map((_, index) => (_jsxs(React.Fragment, { children: [_jsx(Grid, { item: true, xs: tableWidths.actions, children: _jsx(Box, { className: styles.tableValue, children: _jsx(Checkbox, { size: "medium", color: "primary", checked: selectedTableRowIds.includes(index), onChange: e => handleToggleTableRow(e, index) }) }) }), defaultTableFields.map((item, jIndex) => {
                                const value = getTableFieldValue(item, index);
                                const isFieldSelected = item === field && index === fieldIndex;
                                const formattedValue = item.property === TableFieldProperty['length'] && !isFieldSelected && value
                                    ? Number(value).toFixed(2)
                                    : value;
                                return (_jsx(Grid, { item: true, xs: tableWidths[item.property], children: _jsx(Box, { className: classNames(styles.tableValue, {
                                            [styles.active]: isFieldSelected,
                                            [styles.length]: item.property === TableFieldProperty.length,
                                            [styles.missingData]: value === null || value === ''
                                        }), ref: isFieldSelected ? selectedRef : null, onClick: e => handleTableItemClick(e, { item, index: index }), children: _jsx("input", { className: styles.tableFieldInput, onPaste: (e) => {
                                                e.preventDefault();
                                                handleTableFieldChange(onInputPasteText(e, [',']));
                                            }, readOnly: !isFieldSelected, value: formattedValue, onChange: e => handleTableFieldChange(e.target.value) }) }) }, `${index}-${jIndex}`));
                            })] }, index))), _jsx(Grid, { item: true, xs: tableWidths.total, children: _jsx(Box, { className: styles.tableValue, sx: { cursor: 'initial' }, children: _jsx(Button, { className: styles.newRowButton, size: 'small', onClick: handleAddTableRow, startIcon: _jsx(MdAdd, { size: 24, color: "inherit" }), children: tproject('Add row') }) }) })] })] }));
    function resetTableFieldsSelection() {
        setSelectedTableRowIds([]);
    }
    function selectAllTableFields() {
        setSelectedTableRowIds(Array.from({ length: tableRowCount }, (_, index) => index));
    }
}
