import { useState } from 'react';
export function useAnchorElement() {
    const [anchorEl, setAnchorEl] = useState(null);
    const setAnchor = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const clearAnchor = () => {
        setAnchorEl(null);
    };
    return { anchor: anchorEl, setAnchor, clearAnchor };
}
