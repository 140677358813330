import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useEffect } from 'react';
import { useDocuments } from '@entities/project';
import { useTranslation } from 'react-i18next';
import { useTranslationProject } from '@shared/lib/hooks';
import { DocumentsListSkeleton } from './DocumentsListSkeleton';
import { DocumentUpload } from '@pages/Upload';
import { MRTTable } from '@shared/ui/MRTTable';
import { ROUTES } from '@app/constants';
import { useNavigate } from 'react-router-dom';
import styles from './DocumentsList.module.scss';
import { RemoveDocument } from '@features/removeDocument';
import { DocumentStatusLoader } from '@shared/ui/icons/DocumentStatusLoader/DocumentStatusLoader';
import { DocumentStatuses } from '@shared/types/DocumentStatuses';
import { PostProcessingStatuses } from '@shared/types/PostProcessingStatuses';
import { PassIcon } from '@shared/ui/icons/PassIcon';
export function DocumentsList() {
    const { t } = useTranslation();
    const { tproject } = useTranslationProject();
    const { documents, isLoading, invalidateDocumentsList } = useDocuments();
    const navigate = useNavigate();
    const constructDocumentReviewUrl = (status, projectId, docId) => {
        switch (status) {
            case DocumentStatuses.review_ready:
            case DocumentStatuses.ready:
                return ROUTES.DOCUMENT_REVIEW.replace(':projectId', projectId)
                    .replace(':docId', docId)
                    .replace(':pageId', '0');
            case DocumentStatuses.validated:
                return ROUTES.DOCUMENT_SUMMARY.replace(':projectId', projectId).replace(':id', docId);
            default:
                return '';
        }
    };
    const handleCellClick = (cell) => {
        const { column, row } = cell;
        const { status, projectId, id } = row.original;
        if (column.id === 'fileName' && status !== DocumentStatuses.processing) {
            navigate(constructDocumentReviewUrl(status, projectId, id));
        }
    };
    useEffect(() => {
        invalidateDocumentsList();
    }, []);
    const columns = useMemo(() => [
        {
            accessorKey: 'fileName',
            header: t('File name'),
            size: 600,
            Cell: ({ row }) => renderEmptyCellContent(row, 'fileName')
        },
        {
            accessorKey: 'createdAt',
            header: tproject('Upload Date'),
            size: 300,
            Cell: ({ row }) => renderEmptyCellContent(row, 'createdAt')
        },
        {
            accessorKey: 'pageCount',
            header: tproject('Pages'),
            size: 100,
            Cell: ({ row }) => renderEmptyCellContent(row, 'pageCount')
        },
        {
            accessorKey: 'status',
            header: tproject('Status'),
            size: 200,
            Cell: ({ row }) => {
                let statusText;
                switch (row.original.status) {
                    case DocumentStatuses.review_ready:
                    case DocumentStatuses.ready:
                        statusText = tproject('Ready for validation');
                        break;
                    case DocumentStatuses.validated:
                        statusText = (_jsxs("span", { className: styles.statusCell, children: [_jsx(PassIcon, { className: styles.passIcon }), _jsx("div", { children: tproject('Finalised') })] }));
                        break;
                    default:
                        statusText = (_jsxs("span", { className: styles.statusCell, children: [_jsx("div", { className: styles.loader, children: _jsx(DocumentStatusLoader, {}) }), _jsx("div", { children: tproject('Processing...') })] }));
                }
                return _jsx("span", { children: statusText });
            }
        },
        {
            accessorKey: 'delete',
            header: t('Actions'),
            size: 100,
            Cell: ({ row }) => (_jsx("div", { className: styles.deleteIcon, children: _jsx(RemoveDocument, { document: row.original }) })),
            enableSorting: false
        }
    ], [t, tproject]);
    const renderEmptyCellContent = (row, type) => {
        const { status, fileName, createdAt, pageCount } = row.original;
        const isProcessing = !PostProcessingStatuses.includes(status);
        const getContent = (content) => isProcessing ? _jsx("span", { className: styles.dash, children: "\u2014" }) : _jsx("span", { children: content });
        switch (type) {
            case 'fileName':
                return _jsx("span", { className: `${isProcessing ? styles.dash : ''}`, children: fileName });
            case 'createdAt':
                return getContent(createdAt);
            case 'pageCount':
                return getContent(pageCount);
            default:
                return null;
        }
    };
    return (_jsxs(_Fragment, { children: [!isLoading && documents.length === 0 && _jsx(DocumentUpload, {}), _jsxs("div", { className: styles.container, children: [isLoading && _jsx(DocumentsListSkeleton, {}), documents.length > 0 && (_jsx(MRTTable, { rows: documents, columns: columns, handleCellClick: handleCellClick, loading: isLoading }))] })] }));
}
