import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { DialogContainer, DialogActions, DialogContent, DialogTitle } from '@shared/ui/Dialog';
import { useTranslation } from 'react-i18next';
import { useTranslationProject } from '@shared/lib/hooks';
import styles from './EditProjectDialog.module.scss';
import { ProjectForm } from '@entities/project';
import { useUpdateProject } from '@entities/project/lib/hooks';
import { ToastService } from '@shared/lib/services';
import { CircularLoader } from '@shared/ui/Loader';
import { useCurrentProject } from '@entities/project/lib/hooks';
export function EditProjectDialog({ onClose }) {
    const { t } = useTranslation();
    const { tproject } = useTranslationProject();
    const { project: { id, name, location } } = useCurrentProject();
    const onSuccess = () => {
        ToastService.success(tproject('Project updated.'));
        onClose();
    };
    const { update, isUpdating } = useUpdateProject(onSuccess, id);
    const handleSubmit = (formValues) => {
        update(formValues);
    };
    return (_jsxs(DialogContainer, { className: styles.container, fullWidth: true, open: true, children: [_jsx(DialogTitle, { variant: "h5", children: tproject('Edit Project') }), _jsx(DialogContent, { children: _jsx(ProjectForm, { name: name, location: location.name, onSubmit: handleSubmit }) }), _jsxs(DialogActions, { className: styles.actions, children: [_jsx(Button, { variant: "outlined", onClick: onClose, children: t('Cancel') }), _jsx(Button, { type: "submit", form: ProjectForm.FORM_ID, variant: "contained", disabled: isUpdating, endIcon: isUpdating ? _jsx(CircularLoader, { size: "1em", color: "inherit" }) : undefined, children: tproject('Update Project') })] })] }));
}
