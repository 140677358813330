import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useAuth } from '@entities/auth';
import styles from './ProjectsPage.module.scss';
import { AddProjectButton } from '@features/createProject/ui/AddProjectButton';
import { ProjectList } from '@widgets/projectList';
import { useTranslation } from 'react-i18next';
export function ProjectsPage() {
    const { t } = useTranslation();
    const { user } = useAuth();
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.projectsHeader, children: [_jsx("div", { className: styles.projectTitle, children: t('Projects') }), (user === null || user === void 0 ? void 0 : user.hasAdminRole) && _jsx(AddProjectButton, {})] }), _jsx(ProjectList, {})] }));
}
