// @ts-nocheck
import { alpha } from '@mui/material';
const components = {
    MuiCssBaseline: {
        variants: [
            {
                props: {},
                style: ({ theme }) => ({
                    body: {
                        margin: theme.spacing(0)
                    }
                })
            }
        ]
    },
    MuiCheckbox: {
        variants: [
            {
                props: {},
                style: ({ theme }) => ({
                    '&.Mui-checked + .MuiFormControlLabel-label': {
                        color: theme.palette.common.black
                    },
                    '& + .MuiFormControlLabel-label': {
                        color: alpha(theme.palette.common.black, 0.5)
                    }
                })
            },
            {
                props: { size: 'medium' },
                style: ({ theme }) => ({
                    padding: 10,
                    '& + .MuiFormControlLabel-label': {
                        fontSize: 24,
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20px',
                        marginLeft: theme.spacing(10)
                    }
                })
            }
        ]
    },
    MuiButton: {
        defaultProps: {
            variant: 'contained',
            disableElevation: true
        },
        variants: [
            {
                props: {},
                style: ({ theme }) => ({
                    textTransform: 'none'
                })
            },
            {
                props: { size: 'small' },
                style: ({ theme }) => ({
                    height: 44,
                    fontSize: 16,
                    lineHeight: '24px',
                    padding: theme.spacing(10, 18)
                })
            },
            {
                props: { size: 'medium' },
                style: ({ theme }) => ({
                    height: 60,
                    fontSize: 18,
                    lineHeight: '28px',
                    padding: theme.spacing(16, 28),
                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                    borderRadius: 8,
                    '.MuiButton-startIcon': {
                        width: 20,
                        height: 20,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: theme.spacing(8)
                    }
                })
            },
            {
                props: {
                    variant: 'contained'
                },
                style: ({ theme }) => ({
                    '&:disabled': {
                        backgroundColor: '#FFCBD5'
                    }
                })
            },
            {
                props: { variant: 'outlined' },
                style: ({ theme }) => ({
                    color: '#363434',
                    borderWidth: 2,
                    backgroundColor: theme.palette.common.white,
                    '&:hover': {
                        borderWidth: 2
                    },
                    '&:disabled': {
                        color: '#BABABA',
                        borderWidth: 2,
                        borderColor: theme.palette.primary.light
                    }
                })
            }
        ]
    }
};
export default components;
