import { useEffect, useState } from 'react';
import { DocumentOCRService } from '@entities/documentOCR/lib/services/DocumentOCR.service';
export function useDocumentOCR(ocrUrl) {
    const [data, setData] = useState();
    useEffect(() => {
        DocumentOCRService.getOCR(ocrUrl).then((data) => {
            setData(data);
        });
    }, [ocrUrl]);
    return { data: data !== null && data !== void 0 ? data : [] };
}
