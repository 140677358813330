var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { customerAPI } from '@shared/lib/api';
import { plainToInstance } from 'class-transformer';
import { UserModel } from '@entities/user';
export class UserService {
    static getUsers(projectId) {
        return __awaiter(this, void 0, void 0, function* () {
            const users = (yield customerAPI.get(`users/${projectId}`).json());
            return plainToInstance(UserModel, users);
        });
    }
    static inviteUser(projectId, email) {
        return __awaiter(this, void 0, void 0, function* () {
            yield customerAPI
                .post('users/add-to-project', {
                json: {
                    project_id: projectId,
                    user_email: email
                }
            })
                .json();
        });
    }
    static removeUser(projectId, userId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield customerAPI.delete(`users/${projectId}/${userId}`).json();
        });
    }
}
