import { jsx as _jsx } from "react/jsx-runtime";
import stylesDocument from '../document.module.scss';
import { useDocumentStore } from '@entities/project/document';
import { useTranslation } from 'react-i18next';
import { useTranslationProject } from '@shared/lib/hooks';
export function PageField() {
    const { t } = useTranslation();
    const { tproject } = useTranslationProject();
    const { activePageIndex, pageCount } = useDocumentStore();
    return (_jsx("div", { className: stylesDocument.pageNumber, children: `${t('Page')} ${activePageIndex + 1} ${tproject('of')} ${pageCount}` }));
}
