import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { IconButton } from '@shared/ui/Button';
import { DeleteIcon } from '@shared/ui/icons';
import styles from '@widgets/usersList/ui/UserList.module.scss';
import { useRemoveDocument } from '@entities/project';
import { useTranslation } from 'react-i18next';
import { ToastService } from '@shared/lib/services';
import { ConfirmModal } from '@shared/ui';
export function RemoveDocument({ document }) {
    const { t } = useTranslation();
    const { removeDocument, isRemoved } = useRemoveDocument();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    useEffect(() => {
        if (isRemoved) {
            ToastService.success(t('Document was successfully deleted.'));
        }
    }, [isRemoved, t]);
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { onClick: () => {
                    setShowConfirmModal(true);
                }, children: _jsx(DeleteIcon, { className: styles.deleteIcon }) }), showConfirmModal ? (_jsx(ConfirmModal, { open: true, title: t('Confirm file deletion'), description: t('Are you sure you want to delete {{filename}} file?', {
                    filename: document.fileName
                }), okButtonLabel: t('Confirm'), onClose: () => setShowConfirmModal(false), onProceed: () => {
                    removeDocument(document.id);
                    setShowConfirmModal(false);
                } })) : null] }));
}
