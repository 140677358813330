import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { DialogContainer, DialogActions, DialogContent, DialogTitle, DialogCloseButton } from '@shared/ui/Dialog';
import { useTranslation } from 'react-i18next';
import { useTranslationUser } from '@shared/lib/hooks';
import { Button } from '@shared/ui/Button';
import { CircularLoader } from '@shared/ui/Loader';
import { ToastService } from '@shared/lib/services';
import styles from './DeleteUserDialog.module.scss';
import { useRemoveUser } from '@entities/user/lib/hooks';
import { HelperText } from '@shared/ui';
export function DeleteUserDialog({ user, onSuccess, onClose }) {
    const { tuser } = useTranslationUser();
    const { t } = useTranslation();
    const { removeUser, isRemoved, isRemoving } = useRemoveUser();
    const handleDelete = () => {
        removeUser(user.id);
    };
    useEffect(() => {
        if (isRemoved) {
            ToastService.success(tuser('User was successfully deleted.'));
            onClose();
            onSuccess();
        }
    }, [isRemoved, onClose, tuser, onSuccess]);
    return (_jsxs(DialogContainer, { fullWidth: true, open: true, children: [_jsxs(DialogTitle, { className: styles.title, variant: "h5", children: [tuser('Delete User'), _jsx(DialogCloseButton, { onClick: onClose })] }), _jsxs(DialogContent, { className: styles.content, children: [_jsx("div", { children: tuser('Are you sure want to delete User?') }), _jsx("div", { className: styles.email, children: user.email }), _jsx(HelperText, { variant: "outlined", children: tuser('After this action, User will not be able to login into current project, that will not affect access to any other projects.') })] }), _jsxs(DialogActions, { className: styles.actions, children: [_jsx(Button, { onClick: onClose, variant: "outlined", children: t('Cancel') }), _jsx(Button, { onClick: handleDelete, variant: "contained", disabled: isRemoving, endIcon: isRemoving ? _jsx(CircularLoader, { size: "1em", color: "inherit" }) : undefined, children: t('Delete') })] })] }));
}
