import { jsx as _jsx } from "react/jsx-runtime";
import styles from './DocumentImageOCR.module.scss';
import { useDocumentOCR } from '@entities/documentOCR/lib/hooks/useDocumentOCR';
import { memo } from 'react';
export const DocumentImageOCR = memo(function DocumentImageOCR({ dimensions, ocrUrl }) {
    const { data } = useDocumentOCR(ocrUrl);
    if (!dimensions.width || !dimensions.height) {
        return;
    }
    return (_jsx("svg", { className: styles.svg, viewBox: `0 0 ${dimensions.width} ${dimensions.height}`, fill: `red`, children: data.map((ocr, index) => {
            return (_jsx(TextBox, { dimensions: dimensions, text: ocr.text, coordinates: ocr.coordinates }, index));
        }) }));
});
function TextBox({ text, coordinates, dimensions }) {
    const coords = getBoundaryBoxStyle(coordinates, dimensions);
    return (_jsx("text", { x: coords.left, y: coords.top + coords.height, fontSize: coords.height, children: text }));
}
function getBoundaryBoxStyle(coords, dimensions) {
    return {
        top: coords[1] * dimensions.height,
        left: coords[0] * dimensions.width,
        width: coords[2] * dimensions.width,
        height: coords[3] * dimensions.height
    };
}
