import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ToastService } from '@shared/lib/services';
import { DocumentService } from '../services/Document.service';
import { makeListQueryKey } from './utils/documents';
import { useTranslationProject } from '@shared/lib/hooks';
import { useCurrentProject } from '../../../lib/hooks/useCurrentProject';
import { useQueryClient } from '@tanstack/react-query';
import { PostProcessingStatuses } from '@shared/types/PostProcessingStatuses';
export function useDocuments() {
    const { project } = useCurrentProject();
    const queryClient = useQueryClient();
    const { tproject } = useTranslationProject();
    const { data, isLoading, isError, error, refetch } = useQuery({
        queryKey: makeListQueryKey(project.id),
        queryFn: () => DocumentService.getDocuments(project.id).catch(() => {
            throw new Error(tproject('Error occurred while loading the Documents.'));
        }),
        refetchInterval: data => {
            var _a;
            const isDocumentProcessing = (_a = data.state.data) === null || _a === void 0 ? void 0 : _a.find(item => !PostProcessingStatuses.includes(item.status));
            if (isDocumentProcessing) {
                return 3000;
            }
            return false;
        },
        retry: 2
    });
    const invalidateDocumentsList = () => queryClient.invalidateQueries({ queryKey: makeListQueryKey(project.id) });
    const errorMessage = isError && error.message;
    useEffect(() => {
        if (errorMessage) {
            ToastService.error(errorMessage);
        }
    }, [errorMessage]);
    return {
        documents: data || [],
        isLoading,
        invalidateDocumentsList,
        refetch
    };
}
