import { useState, useEffect } from 'react';
import { setCookie, getCookie } from '@shared/utils';
import { GroupingRowType } from '../types/DocumentResultRowType';
import { summaryTableGroupingState } from '../constants/summaryTableGroupingState';
export function useSummaryTableGrouping() {
    const initialGroupingState = getCookie(summaryTableGroupingState) || GroupingRowType.ungrouped;
    const [groupingState, setGroupingState] = useState(initialGroupingState);
    useEffect(() => {
        if (!getCookie(summaryTableGroupingState)) {
            setCookie(summaryTableGroupingState, GroupingRowType.ungrouped, 1);
        }
    }, []);
    const changeGroupingState = (val) => {
        setGroupingState(val);
        setCookie(summaryTableGroupingState, val, 1);
    };
    return {
        groupingState,
        changeGroupingState
    };
}
