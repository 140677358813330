import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { TextField } from '@shared/ui/TextField';
import { useTranslationUser } from '@shared/lib/hooks';
import styles from './UserInvitationForm.module.scss';
import { Formik } from 'formik';
import { HelperText } from '@shared/ui';
export function UserInvitationForm({ onSubmit }) {
    const { tuser } = useTranslationUser();
    const validate = useMemo(() => getValidate(tuser), [tuser]);
    return (_jsx(Formik, { initialValues: { email: '' }, onSubmit: onSubmit, validate: validate, children: ({ handleChange, handleBlur, handleSubmit, values, errors }) => (_jsxs("form", { id: FORM_ID, onSubmit: handleSubmit, noValidate: true, children: [_jsx(TextField, { className: styles.emailField, onChange: handleChange, onBlur: handleBlur, name: "email", type: "email", label: tuser('Email'), value: values.email, error: !!errors.email, helperText: errors.email || ' ', required: true, fullWidth: true }), _jsx(HelperText, { variant: "outlined", children: tuser('The invitation will be send per e-mail, if user does not have a Conxai account, registration will be required.') })] })) }));
}
const FORM_ID = 'invitation-form';
UserInvitationForm.FORM_ID = FORM_ID;
const getFieldValidations = (tuser) => {
    return {
        email: (email) => {
            const isValid = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email || '');
            if (!isValid) {
                return tuser('Please enter a valid e-mail address');
            }
            return undefined;
        }
    };
};
function getValidate(tuser) {
    const validateFnMap = getFieldValidations(tuser);
    return function (values) {
        const errors = {};
        const emailError = validateFnMap.email(values.email);
        if (emailError) {
            errors.email = emailError;
        }
        return errors;
    };
}
