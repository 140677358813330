import { TableFieldProperty } from '@shared/types';
export const icTableColumnsWidth = {
    total: 36,
    actions: 4,
    [TableFieldProperty.diameter]: 6,
    [TableFieldProperty.length]: 6,
    [TableFieldProperty.id]: 6,
    [TableFieldProperty.quantities]: 6,
    [TableFieldProperty.material_name]: 8
};
