import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, TableRow, TableCell } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@app/constants';
import { resultFields } from '../lib/constants/resultFields';
import { getResultRowsIndexesWithErrors } from '../lib/state/selectors/getResultRowsIndexesWithErrors';
import { useSummaryStore } from '@entities/summary';
import { ResultFieldProperties } from '@shared/types/ResultFieldProperties';
import { resultValueNotAppliable } from '../lib/constants/resultValueNotAppliable';
import { ResultsTableReadOnlyCell } from './ResultsTableReadOnlyCell';
import styles from './SummaryTable.module.scss';
import classNames from 'classnames';
import { documentTypes } from '@entities/project/document';
import { useCurrentProject } from '@entities/project';
import { isFieldTypeTagOrDelivery } from '@shared/utils/isFieldTypeTagOrDelivery';
import { EditNote } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
export function ResultsTableRow({ row, isLastGroupedItem = false }) {
    const { reset } = useSummaryStore();
    const { index, icIndex } = row;
    const rowIndexesWithError = useSummaryStore(getResultRowsIndexesWithErrors);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { project } = useCurrentProject();
    const handleEditPage = (documentId, pageId) => () => {
        if (documentId) {
            navigate(ROUTES.DOCUMENT_REVIEW.replace(':projectId', project.id)
                .replace(':docId', documentId)
                .replace(':pageId', (pageId - 1).toString()));
            reset();
        }
    };
    return (_jsxs(TableRow, { className: classNames(styles.resultTableRow, {
            [styles.certificate]: row.type === 'certificate',
            [styles.tags]: row.type === 'tags',
            [styles.delivery]: row.type === 'delivery',
            [styles['row-has-error']]: rowIndexesWithError.includes(index),
            [styles['last-row-group-item']]: isLastGroupedItem
        }), id: `${rowIndexesWithError.includes(index) ? 'row-has-error' : ''} ${index}`, "data-id": index, "data-icindex": icIndex || (isFieldTypeTagOrDelivery(row === null || row === void 0 ? void 0 : row.type) ? Number.MAX_SAFE_INTEGER - 1 : index), children: [resultFields.map((field, fieldIndex) => {
                const value = getFieldValue(row, field, index);
                return (_jsx(ResultsTableReadOnlyCell, { index: index, property: field.property, value: value }, `result-table-body-cell-${fieldIndex}`));
            }), _jsx(TableCell, { className: styles.resultTableCell, children: _jsx(IconButton, { onClick: handleEditPage(row.documentId, row.pageNumber), children: _jsx(EditNote, { fontSize: 'large' }) }) })] }));
    function getFieldValue(row, field, index) {
        if (isFieldTypeTagOrDelivery(row.type)) {
            if ([
                ResultFieldProperties.companyName,
                ResultFieldProperties.shippingDate,
                ResultFieldProperties.documentNumber,
                ResultFieldProperties.materialName,
                ResultFieldProperties.totalAmount
            ].includes(field.property)) {
                return resultValueNotAppliable;
            }
        }
        switch (field.property) {
            case 'index': {
                return index + 1;
            }
            case 'filename': {
                return row.documentName || null;
            }
            case 'type': {
                return t(documentTypes[row[field.property]]);
            }
            case 'totalAmount':
            case 'shippingDate':
                return row[field.property] !== null ? row[field.property] : '';
            case 'pageNumber':
            case 'companyName':
            case 'certificateNumber':
            case 'documentNumber':
            case 'materialName':
            case 'diameter':
            case 'id':
            case 'quantities': {
                return row[field.property];
            }
            case 'length': {
                return row[field.property] !== null ? row[field.property] + ' m' : '';
            }
            default: {
                return null;
            }
        }
    }
}
