import { TableFieldPropertyTypes } from '@shared/types';
import { calcTableRowCount, useDocumentStore } from '../state/useDocumentStore';
import { useSelectedFieldStore } from '../state/useSelectedFieldStore';
import { isGeneralField } from '../utils/isGeneralField';
export function useUpdateTable() {
    const { updatePage, page, pageId } = useDocumentStore();
    const { field } = useSelectedFieldStore();
    const tableRowCount = calcTableRowCount();
    function getTableRowData(fieldIndex) {
        if (!page) {
            console.log('Page not found');
            // @ts-ignore
            return {};
        }
        let row = {};
        const tableEntries = Object.entries(page.predictions.table);
        for (let i = 0; i < tableEntries.length; i++) {
            row = Object.assign(Object.assign({}, row), { [tableEntries[i][0]]: {
                    coordinates: [],
                    values: tableEntries[i][1].values[fieldIndex]
                } });
        }
        return row;
    }
    function updateCellValue(newValue, fieldIndex) {
        if (field && !isGeneralField(field) && page.predictions.table[field.property] && page) {
            if (field.type === TableFieldPropertyTypes.integer &&
                newValue !== null &&
                !newValue.toString().match(/^[0-9]*$/)) {
                return;
            }
            if (field.type === TableFieldPropertyTypes.float &&
                newValue !== null &&
                !newValue.toString().match(/^[0-9]*\.?[0-9]*$/)) {
                return;
            }
            updatePage(Object.assign(Object.assign({}, page), { predictions: Object.assign(Object.assign({}, page.predictions), { table: Object.assign(Object.assign({}, page.predictions.table), { [field.property]: {
                            coordinates: page.predictions.table[field.property].coordinates,
                            values: new Array(tableRowCount).fill(null).map((v, index) => {
                                if (fieldIndex === index) {
                                    return newValue;
                                }
                                else {
                                    const fieldValues = page.predictions.table[field.property].values;
                                    if (fieldValues[index]) {
                                        return fieldValues[index];
                                    }
                                    else {
                                        return v;
                                    }
                                }
                            })
                        } }) }) }));
        }
    }
    function addNewRow(values) {
        if (page && pageId !== undefined) {
            let table = {};
            const tableEntries = Object.entries(page.predictions.table);
            for (let i = 0; i < tableEntries.length; i++) {
                table = Object.assign(Object.assign({}, table), { [tableEntries[i][0]]: {
                        coordinates: [
                            ...tableEntries[i][1].coordinates,
                            values ? values[tableEntries[i][0]].coordinates : []
                        ],
                        values: [
                            ...tableEntries[i][1].values,
                            values ? values[tableEntries[i][0]].values : null
                        ]
                    } });
            }
            updatePage(Object.assign(Object.assign({}, page), { predictions: Object.assign(Object.assign({}, page.predictions), { table }) }));
        }
    }
    return {
        addNewRow,
        updateCellValue,
        getTableRowData
    };
}
