import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useRef, useMemo } from 'react';
import { resultFields } from '../lib/constants/resultFields';
import { Toolbar } from './Toolbar';
import { ResultsTableRow } from './ResultsTableRow';
import { getGroupedResultDataByIC } from '../lib/state/selectors/getGroupedResultDataByIC';
import { useSummaryStore } from '@entities/summary';
import { useTranslation } from 'react-i18next';
import { GroupingRowType } from '../lib/types/DocumentResultRowType';
import classNames from 'classnames';
import { useSummaryTableGrouping } from '../lib/hooks/useSummaryTableGrouping';
import styles from './SummaryTable.module.scss';
export function ResultsTable({ data }) {
    const tableRef = useRef(null);
    const { t } = useTranslation();
    const { groupingState, changeGroupingState } = useSummaryTableGrouping();
    const groupedData = useSummaryStore(getGroupedResultDataByIC);
    const { documentId } = useSummaryStore();
    const itemData = useMemo(() => {
        if (groupingState === GroupingRowType.grouped) {
            return [
                ...Object.values(groupedData.grouped)
                    .map(item => [...item, 'divider'])
                    .flat(),
                ...Object.values(groupedData.unassigned)
            ];
        }
        return data;
    }, [groupingState]);
    return (_jsxs(_Fragment, { children: [_jsx(Toolbar, { groupingStatus: groupingState, handleChangeGroupingStatus: changeGroupingState }), _jsxs(Table, { className: styles.resultTable, ref: tableRef, children: [_jsx(TableHead, { className: classNames(styles.resultTableHead, {
                            [styles.resultTableHead__isSticky]: !documentId
                        }), children: _jsxs(TableRow, { className: styles.resultTableRow, children: [resultFields.map((item, index) => (_jsx(TableCell, { className: styles.resultTableCell, children: _jsx("div", { className: styles.header, children: _jsx(Box, { children: t(item.label) }) }) }, index))), _jsx(TableCell, { className: styles.resultTableCell, children: _jsx(Box, { sx: { fontSize: 12, lineHeight: 1.5 }, children: t('Action') }) })] }) }), _jsx(TableBody, { children: itemData.map((rowItem, idx) => {
                            if (typeof rowItem === 'string') {
                                return _jsx(_Fragment, {});
                            }
                            else
                                return (_jsx(ResultsTableRow, { row: rowItem, isLastGroupedItem: idx < itemData.length - 2 ? typeof itemData[idx + 1] === 'string' : false }, `res-table-row-${rowItem.index}`));
                        }) })] })] }));
}
