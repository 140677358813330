import { TableFieldProperty, TableFieldPropertyTypes } from '@shared/types';
export const tableFields = [
    {
        label: 'Diameter',
        property: TableFieldProperty.diameter,
        type: TableFieldPropertyTypes.string
    },
    {
        label: 'Length',
        property: TableFieldProperty.length,
        type: TableFieldPropertyTypes.float
    },
    {
        label: 'ID',
        property: TableFieldProperty.id,
        type: TableFieldPropertyTypes.string
    },
    {
        label: 'Quantity',
        property: TableFieldProperty.quantities,
        type: TableFieldPropertyTypes.integer
    }
];
