var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@shared/lib/hooks';
import { ProjectService } from '../services/Project.service';
import { useTranslationProject } from '@shared/lib/hooks';
import { LIST_QUERY_KEY } from './constants/project';
import { useCallback } from 'react';
import { AuthService } from '@shared/lib/services';
const QUERY_OPTIONS = {
    queryKey: LIST_QUERY_KEY,
    skipInvalidation: true
};
export function useCreateProject(onSuccess) {
    const { tproject } = useTranslationProject();
    const createProject = useCallback((project) => ProjectService.createProject(project).catch(() => {
        throw new Error(tproject('Error occurred. Please try to upload an image again.'));
    }), [tproject]);
    const { mutate: create, isLoading: isCreating, isSuccess: isCreated } = useMutation({
        mutationFn: createProject,
        onSuccess: (client, data) => __awaiter(this, void 0, void 0, function* () {
            const projects = client.getQueryData(LIST_QUERY_KEY);
            const updatedProjects = [...projects, data];
            client.setQueryData(LIST_QUERY_KEY, updatedProjects);
            yield AuthService.currentSession(true);
            onSuccess(data);
        }),
        options: QUERY_OPTIONS
    });
    return {
        create,
        isCreating,
        isCreated
    };
}
