export var ResultFieldProperties;
(function (ResultFieldProperties) {
    ResultFieldProperties["index"] = "index";
    ResultFieldProperties["filename"] = "filename";
    ResultFieldProperties["type"] = "type";
    ResultFieldProperties["pageNumber"] = "pageNumber";
    ResultFieldProperties["companyName"] = "companyName";
    ResultFieldProperties["shippingDate"] = "shippingDate";
    ResultFieldProperties["certificateNumber"] = "certificateNumber";
    ResultFieldProperties["documentNumber"] = "documentNumber";
    ResultFieldProperties["materialName"] = "materialName";
    ResultFieldProperties["totalAmount"] = "totalAmount";
    ResultFieldProperties["diameter"] = "diameter";
    ResultFieldProperties["length"] = "length";
    ResultFieldProperties["id"] = "id";
    ResultFieldProperties["quantities"] = "quantities";
})(ResultFieldProperties || (ResultFieldProperties = {}));
