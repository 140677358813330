export var PagePredictionProperties;
(function (PagePredictionProperties) {
    PagePredictionProperties["type"] = "type";
    PagePredictionProperties["certificate_number"] = "certificate_number";
    PagePredictionProperties["company_name"] = "company_name";
    PagePredictionProperties["document_number"] = "document_number";
    PagePredictionProperties["shipping_date"] = "shipping_date";
    PagePredictionProperties["total_amount"] = "total_amount";
    PagePredictionProperties["table"] = "table";
})(PagePredictionProperties || (PagePredictionProperties = {}));
export var TableFieldProperty;
(function (TableFieldProperty) {
    TableFieldProperty["diameter"] = "diameter";
    TableFieldProperty["length"] = "length";
    TableFieldProperty["id"] = "id";
    TableFieldProperty["quantities"] = "quantities";
    TableFieldProperty["material_name"] = "material_name";
})(TableFieldProperty || (TableFieldProperty = {}));
export var TableFieldPropertyTypes;
(function (TableFieldPropertyTypes) {
    TableFieldPropertyTypes["string"] = "string";
    TableFieldPropertyTypes["integer"] = "integer";
    TableFieldPropertyTypes["float"] = "float";
})(TableFieldPropertyTypes || (TableFieldPropertyTypes = {}));
export var PageTypes;
(function (PageTypes) {
    PageTypes["certificate"] = "certificate";
    PageTypes["tags"] = "tags";
    PageTypes["delivery"] = "delivery";
    PageTypes["undefined"] = "undefined";
})(PageTypes || (PageTypes = {}));
export var FieldProperty;
(function (FieldProperty) {
    FieldProperty["company_name"] = "company_name";
    FieldProperty["shipping_date"] = "shipping_date";
    FieldProperty["certificate_number"] = "certificate_number";
    FieldProperty["document_number"] = "document_number";
    FieldProperty["total_amount"] = "total_amount";
})(FieldProperty || (FieldProperty = {}));
