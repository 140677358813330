import { memoize } from 'proxy-memoize';
import { PageTypes } from '@shared/types';
const getResultCertificateNumbersMemo = memoize((data) => {
    const icNumbers = {};
    data.forEach(row => {
        if (row.type === PageTypes.certificate && !icNumbers[row.index]) {
            icNumbers[row.index] = row.certificateNumber;
        }
    });
    return icNumbers;
});
export const getResultCertificateNumbers = (data) => {
    if (!data) {
        return {};
    }
    return getResultCertificateNumbersMemo(data);
};
