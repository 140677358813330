var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Expose, Transform } from 'class-transformer';
import { parseISO, format } from 'date-fns';
import { dateFormat } from '@shared/lib/constants';
export class DocumentModel {
}
__decorate([
    Expose({ name: 'id' })
], DocumentModel.prototype, "id", void 0);
__decorate([
    Expose({ name: 'created_at' }),
    Transform(({ value }) => (value ? format(parseISO(value), dateFormat) : value))
], DocumentModel.prototype, "createdAt", void 0);
__decorate([
    Expose({ name: 'created_by' })
], DocumentModel.prototype, "createdBy", void 0);
__decorate([
    Expose({ name: 'file_name' }),
    Transform(({ value }) => {
        const regex = /[^;]+\.[a-z0-9]+/gi;
        const matches = value === null || value === void 0 ? void 0 : value.match(regex);
        if (matches && matches.length > 0) {
            return matches[0].replace(/(^["']|["']$)/g, '');
        }
        return null;
    })
], DocumentModel.prototype, "fileName", void 0);
__decorate([
    Expose({ name: 'project_id' })
], DocumentModel.prototype, "projectId", void 0);
__decorate([
    Expose({ name: 'status' })
], DocumentModel.prototype, "status", void 0);
__decorate([
    Expose({ name: 'page_count' })
], DocumentModel.prototype, "pageCount", void 0);
__decorate([
    Expose({ name: 'csv' })
], DocumentModel.prototype, "csv", void 0);
__decorate([
    Expose({ name: 'json' })
], DocumentModel.prototype, "json", void 0);
