var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ToastService } from '@shared/lib/services';
import { prepareResults } from '../utils/prepareResults';
import { validateResults } from '../utils/validateResults';
import { SummaryService } from '../service/Summary.service';
import { groupErrorsByProperty } from '../utils/groupErrorsByProperty';
const getInitialState = () => ({
    documentId: undefined,
    document: undefined,
    data: undefined,
    errors: [],
    groupedErrors: undefined
});
export const useSummaryStore = create()(devtools((set, get) => (Object.assign(Object.assign({}, getInitialState()), { fetchSummary: (projectId, id) => __awaiter(void 0, void 0, void 0, function* () {
        if (id) {
            Promise.all([
                SummaryService.getDocument(projectId, id),
                SummaryService.getDocumentEnricher(projectId, id)
            ])
                .then(([document, results]) => {
                const data = prepareResults(results);
                const errors = validateResults(data, id);
                const groupedErrors = groupErrorsByProperty(errors);
                set({
                    documentId: id,
                    document,
                    data,
                    errors,
                    groupedErrors
                });
            })
                .catch(err => ToastService.error(err));
        }
        else {
            try {
                const result = yield SummaryService.getProjectEnricher(projectId);
                const data = prepareResults(result);
                const errors = validateResults(data);
                const groupedErrors = groupErrorsByProperty(errors);
                set({
                    data,
                    errors,
                    groupedErrors
                });
            }
            catch (err) {
                ToastService.error(err);
            }
        }
    }), reset: () => set(getInitialState()) }))));
