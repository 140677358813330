import { stringFormat } from '@shared/lib/utils/stringFormat';
import { PageTypes } from '@shared/types';
import { getResultCertificateNumbers } from '../state/selectors/getResultCertificateNumbers';
import { DocumentResultErrorTypes, DocumentResultErrors } from '@shared/types/DocumentResultError';
import { ResultFieldProperties } from '@shared/types/ResultFieldProperties';
import { isFieldTypeTagOrDelivery } from '@shared/utils/isFieldTypeTagOrDelivery';
import { t } from 'i18next';
function getFilteredArrayLength(arr, type) {
    return arr.find(row => row.type === type);
}
function pushWarningOnce(errors, errorPayload) {
    const exists = errors.some(error => error.type === errorPayload.type && error.message === errorPayload.message);
    if (!exists) {
        errors.push(errorPayload);
    }
}
export function validateResults(results, id) {
    const errors = [];
    const icNumbers = Object.values(getResultCertificateNumbers(results));
    const tagExists = getFilteredArrayLength(results, PageTypes.tags);
    const drExists = getFilteredArrayLength(results, PageTypes.delivery);
    results.forEach((row, rowIndex) => {
        if (isFieldTypeTagOrDelivery(row.type)) {
            if (row.certificateNumber === null || row.certificateNumber === '') {
                let message;
                if (row.type === PageTypes.tags) {
                    message = t(DocumentResultErrors.tagCouldNotMatchToCertificate);
                }
                if (row.type === PageTypes.delivery) {
                    message = t(DocumentResultErrors.deliveryRecordCouldNotMatchToCertificate);
                }
                errors.push(Object.assign({
                    index: rowIndex,
                    type: DocumentResultErrorTypes.error,
                    property: ResultFieldProperties.certificateNumber
                }, { message }));
            }
            else if (!icNumbers.includes(row.certificateNumber)) {
                errors.push({
                    index: rowIndex,
                    type: DocumentResultErrorTypes.warning,
                    property: ResultFieldProperties.certificateNumber,
                    message: t(DocumentResultErrors.certificateNumberIsInvalid)
                });
            }
        }
        else if (row.type === PageTypes.certificate) {
            if (row.calculatedQuantitiesTags !== undefined &&
                +row.quantities !== row.calculatedQuantitiesTags) {
                if (tagExists) {
                    errors.push({
                        index: rowIndex,
                        type: DocumentResultErrorTypes.error,
                        property: ResultFieldProperties.quantities,
                        message: stringFormat(t(DocumentResultErrors.quantitiesDoNotMatchWithCalculatedTags), [
                            `${row.quantities}`,
                            `${row.calculatedQuantitiesTags}`
                        ])
                    });
                }
                else {
                    pushWarningOnce(errors, {
                        type: DocumentResultErrorTypes.warning,
                        message: id
                            ? t(DocumentResultErrors.missingTagsDocument)
                            : t(DocumentResultErrors.missingTagsProject)
                    });
                }
            }
            if (row.calculatedQuantitiesDelivery !== undefined &&
                +row.quantities !== row.calculatedQuantitiesDelivery) {
                if (drExists) {
                    errors.push({
                        index: rowIndex,
                        type: DocumentResultErrorTypes.error,
                        property: ResultFieldProperties.quantities,
                        message: stringFormat(t(DocumentResultErrors.quantitiesDoNotMatchWithCalculatedDelivery), [`${row.quantities}`, `${row.calculatedQuantitiesDelivery}`])
                    });
                }
                else {
                    pushWarningOnce(errors, {
                        type: DocumentResultErrorTypes.warning,
                        message: id
                            ? t(DocumentResultErrors.missingDeliveryDocument)
                            : t(DocumentResultErrors.missingDeliveryProject)
                    });
                }
            }
        }
    });
    return errors;
}
