const typography = {
    fontFamily: 'Inter',
    title: {
        fontSize: 24,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '38px',
        fontFamily: 'Inter'
    }
};
export default typography;
