import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { Box, FormControl, Menu, MenuItem, Typography } from '@mui/material';
import { languages, LanguagesEnum } from '../lib/types/Languages';
import { useAnchorElement } from '@shared/lib/hooks/useAnchorElement';
import styles from './LanguageSwitcher.module.scss';
export function LanguageSwitcher() {
    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState('');
    const { anchor, setAnchor, clearAnchor } = useAnchorElement();
    useEffect(() => {
        const defaultLanguage = languages.map(({ code }) => code).includes(i18n.language)
            ? i18n.language
            : LanguagesEnum.EN;
        setCurrentLanguage(defaultLanguage);
    }, [i18n.language]);
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        clearAnchor();
    };
    const open = Boolean(anchor);
    return (_jsxs(Box, { className: styles.container, children: [_jsx(Box, { className: styles.iconContainer, children: _jsx(LanguageOutlinedIcon, { className: styles.icon }) }), _jsxs(FormControl, { children: [_jsx(Typography, { className: styles.label, onClick: setAnchor, children: currentLanguage }), _jsx(Menu, { open: open, anchorEl: anchor, onClose: clearAnchor, children: languages.map(({ code, label }) => {
                            return (_jsx(MenuItem, { className: styles.item, onClick: () => changeLanguage(code), children: label }, code));
                        }) })] })] }));
}
