import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslationProject } from '@shared/lib/hooks';
import { useNavigate } from 'react-router-dom';
import { ProjectListSkeleton } from './ProjectListSkeleton';
import { MRTTable } from '@shared/ui/MRTTable';
import styles from './ProjectList.module.scss';
import { useGetProjects } from '@entities/project';
import { ROUTES } from '@app/constants';
import { useGetProjectsData } from '@entities/project';
export function ProjectList() {
    const { tproject } = useTranslationProject();
    const { projects, isFetching } = useGetProjects();
    const navigate = useNavigate();
    const { data, isLoading } = useGetProjectsData();
    const columns = useMemo(() => [
        {
            accessorKey: 'name',
            header: tproject('Project Name'),
            size: 400
        },
        {
            accessorKey: 'location',
            header: tproject('Location'),
            size: 300,
            Cell: ({ row }) => {
                return _jsx("span", { children: row.original.location.name });
            }
        },
        {
            accessorKey: 'docCount',
            header: tproject('Documents'),
            size: 200
        },
        {
            accessorKey: 'createdAt',
            header: tproject('Creation date'),
            size: 300
        }
    ], [tproject]);
    return (_jsxs("div", { className: styles.container, children: [isFetching && _jsx(ProjectListSkeleton, {}), !isFetching && projects.length === 0 && (_jsx("div", { className: styles.emptyprojectsContainer, children: _jsx("div", { className: styles.emptyProjectPlaceholderText, children: tproject('You have no project available. Add a new project.') }) })), projects.length > 0 && (_jsx(MRTTable, { rows: data, columns: columns, handleCellClick: ({ row }) => navigate(ROUTES.PROJECT_DOCUMENTS.replace(':projectId', row.original.id)), loading: isLoading }))] }));
}
