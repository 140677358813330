var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { toast, Toaster as BaseToaster, ToastBar } from 'react-hot-toast';
import { IconButton } from '../Button';
import { CloseIcon, ErrorIcon, TaskAltIcon } from '../icons';
import styles from './Toaster.module.scss';
import classNames from 'classnames';
const TOASTER_OPTIONS = {
    success: {
        className: classNames(styles.toastBar, styles.toastBar__success),
        icon: _jsx(TaskAltIcon, { className: classNames(styles.icon, styles.icon__success) })
    },
    error: {
        className: classNames(styles.toastBar, styles.toastBar__error),
        icon: _jsx(ErrorIcon, { className: classNames(styles.icon, styles.icon__error) })
    }
};
export function Toaster(_a) {
    var { containerClassName } = _a, restProps = __rest(_a, ["containerClassName"]);
    return (_jsx(BaseToaster, Object.assign({ position: "top-center" }, restProps, { containerClassName: classNames(styles.container, containerClassName), toastOptions: TOASTER_OPTIONS, children: t => (_jsx(ToastBar, { toast: t, children: ({ icon, message }) => (_jsxs(_Fragment, { children: [icon, message, t.type !== 'loading' && (_jsx(IconButton, { className: styles.closeBtn, onClick: () => toast.dismiss(t.id), children: _jsx(CloseIcon, {}) }))] })) })) })));
}
