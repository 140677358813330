import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Grid, Container, Divider } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { Popover } from '@mui/material';
// @ts-ignore
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import styles from './Datepicker.module.scss';
export const Datepicker = ({ show, anchorEl, onClose, onConfirm, data }) => {
    const [value, setValue] = useState(data);
    const { t } = useTranslation();
    const handleConfirm = () => {
        onConfirm({
            target: {
                value
            }
        });
        setValue('');
        onClose();
    };
    const handleDateChange = (date) => {
        setValue(format(date, 'yyyy-MM-dd'));
    };
    const setTodayDate = () => {
        setValue(format(new Date(), 'yyyy-MM-dd'));
    };
    return (_jsxs(Popover, { className: styles.datepicker, open: show, anchorEl: anchorEl, onClose: onClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'center'
        }, children: [_jsx(Container, { style: { padding: 20 }, children: _jsx(Calendar, { value: value ? new Date(value + 'T00:00:00.000') : new Date(), onChange: handleDateChange, maxDate: new Date() }) }), _jsx(Divider, {}), _jsxs(Grid, { container: true, p: [12, 14], children: [_jsx(Grid, { item: true, xs: 4, children: _jsx(Button, { size: "large", variant: "text", onClick: setTodayDate, className: styles.secondaryButton, color: "secondary", children: t('Today') }) }), _jsxs(Grid, { item: true, spacing: 8, container: true, xs: 8, children: [_jsx(Grid, { item: true, children: _jsx(Button, { size: "large", variant: "outlined", onClick: onClose, className: styles.primaryButton, children: t('Cancel') }) }), _jsx(Grid, { item: true, children: _jsx(Button, { size: "large", onClick: handleConfirm, className: styles.primaryButton, children: t('Confirm') }) })] })] })] }));
};
