import { PageTypes } from '../../../../shared/types';
import { DocumentTypes } from '../types/DocumentTypes';
export function filterResultDataByDocumentType(data, documentTypes) {
    return documentTypes[DocumentTypes.IC] &&
        documentTypes[DocumentTypes.tag] &&
        documentTypes[DocumentTypes.delivery]
        ? data
        : data.filter((row) => {
            if (!documentTypes[DocumentTypes.IC] && row.type === PageTypes.certificate) {
                return false;
            }
            if (!documentTypes[DocumentTypes.tag] && row.type === PageTypes.tags) {
                return false;
            }
            if (!documentTypes[DocumentTypes.delivery] && row.type === PageTypes.delivery) {
                return false;
            }
            return true;
        });
}
