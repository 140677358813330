import { TableFieldProperty } from '@shared/types';
export const tableColumnsWidth = {
    total: 9,
    actions: 1,
    [TableFieldProperty.diameter]: 2,
    [TableFieldProperty.length]: 2,
    [TableFieldProperty.id]: 2,
    [TableFieldProperty.quantities]: 2,
    [TableFieldProperty.material_name]: 0
};
